import { Typography, Chip } from '@mui/material';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from 'react';

function StatusTicket({ status }) {
  switch (status) {
    case 'open':
      return (
        <Chip
          size="small"
          icon={<FiberNewOutlinedIcon />}
          label={'Open'}
          color="primary"
          variant="outlined"
        />
      );
    case 'pending':
      return (
        <Chip
          size="small"
          icon={<PendingActionsOutlinedIcon />}
          label={'Pending'}
          color="primary"
          variant="outlined"
        />
      );
    case 'accepted':
      return (
        <Chip
          size="small"
          icon={<CheckCircleOutlinedIcon />}
          label={'Accepted'}
          color="primary"
          variant="outlined"
        />
      );
    case 'verified':
      return (
        <Chip
          size="small"
          icon={<VerifiedUserOutlinedIcon />}
          label={'Verified'}
          color="primary"
          variant="outlined"
        />
      );
    case 'testing':
      return (
        <Chip
          size="small"
          icon={<BugReportOutlinedIcon />}
          label={'Testing'}
          color="primary"
          variant="outlined"
        />
      );
    case 'shipped':
      return (
        <Chip
          size="small"
          icon={<LocalShippingOutlinedIcon />}
          label={'Arrived'}
          color="primary"
          variant="outlined"
        />
      );
    case 'close':
      return (
        <Chip
          size="small"
          icon={<LockOutlinedIcon />}
          label={'Closed'}
          color="primary"
          variant="outlined"
        />
      );
    default:
      return <Chip size="small" label={status} color="primary" variant="outlined" />;
  }
}

export default StatusTicket;
