import { createSlice } from '@reduxjs/toolkit'
import { getAllCustomers, getCustomer } from './asyncCustomers'

const initialState = {
  isLoading: true,
  isError: false,
  data: [],
  customer: {},
  meta: {}
}

export const CUSTOMERS_SLICE = createSlice({
  name: 'customers',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCustomers.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta
      })
      .addCase(getAllCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true
      })
  },
})

// Action creators are generated for each case reducer function

export default CUSTOMERS_SLICE.reducer