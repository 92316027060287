import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Button } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
//
import Searchbar from './Searchbar';
import zsvg from '../../../assets/z_logo.svg'
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

// ----------------------------------------------------------------------

const NAV_WIDTH = 233;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const navigate = useNavigate();
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >

          <MenuIcon />
        </IconButton>

        <Searchbar />
        <Box sx={{ flexGrow: 1 }} />
        {/* <Button onClick={() => navigate("/auth/login")}>LOGIN</Button>
        <Button onClick={() => navigate("/auth/logout")}>LOGOUT</Button>
        <Button onClick={() => localStorage.clear()}>CLEAR</Button> */}

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <img src={zsvg} style={{ width: 180 }} alt="zotacLogo" />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
