const MICROSOFT_URL = 'https://graph.microsoft.com/v1.0';
// Dev Url
const dev = process.env.REACT_APP_API_URL ? false : true;
const apiURLDEV = dev ? "http://localhost:3001" : "https://api.portal.zotac.service4k.com"
const portalURLDEV = dev ? "http://localhost:3000" : "https://portal.zotac.service4k.com"
// 
const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `${apiURLDEV}/wapi`;
const accessToken = localStorage.getItem('microsoftJWT')
  ? localStorage.getItem('microsoftJWT')
  : 'Bearer 497ec592d9c15fb55749462ddd587414c091ee4ec1752b5f943d0b5cd271bedf0ad35dfd52a3d82b3ace0481d74fbbcc3398746f5311e73cea4a7edf2a7f9e3f83b4760aa49ae413e3c81f5ecdf29529d4e16e17106aadb9b58b544ae8fa3d6bf1a72bdc952ceb13de14ba065f2e04ca53c86e08e03d0e0c7ddb662ec0166077';
const REACT_APP_SSO_BACKEND = process.env.REACT_APP_SSO_BACKEND
  ? process.env.REACT_APP_SSO_BACKEND
  : apiURLDEV
const API_URL_PREFIX = process.env.REACT_APP_SSO_BACKEND
  ? process.env.REACT_APP_SSO_BACKEND
  : apiURLDEV


const env = {
  MICROSOFT_URL,
  API_URL,
  accessToken,
  REACT_APP_SSO_BACKEND,
  API_URL_PREFIX,
};

export default env;
