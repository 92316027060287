import env from '../../env';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { recallLoginSSO } from 'src/utils/loginUtils';

//Async Functions
export const getDashboardAccepted = createAsyncThunk(
  'dashboard/getDashboardAccepted',
  async (arg) => {
    const res = await axios
      .get(`${env.API_URL_PREFIX}/stats/accepted/allowed`, {
        headers: {
          jwt: localStorage.getItem('microsoftJWT'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((data) => {
        return data.data;
      })
      .catch((err) => {
        recallLoginSSO();
        return err.response;
      });
    return res;
  }
);
export const getDashboardArrived = createAsyncThunk(
  'dashboard/getDashboardArrived',
  async (arg) => {
    const res = await axios
      .get(`${env.API_URL_PREFIX}/stats/shipped/allowed`, {
        headers: {
          jwt: localStorage.getItem('microsoftJWT'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((data) => {
        return data.data;
      })
      .catch((err) => {
        recallLoginSSO();
        return err.response;
      });
    return res;
  }
);
export const getDashboardTesting = createAsyncThunk(
  'dashboard/getDashboardTesting',
  async (arg) => {
    const res = await axios
      .get(`${env.API_URL_PREFIX}/stats/testing/allowed`, {
        headers: {
          jwt: localStorage.getItem('microsoftJWT'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((data) => {
        return data.data;
      })
      .catch((err) => {
        recallLoginSSO();
        return err.response;
      });
    return res;
  }
);
