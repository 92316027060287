import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import actions from '../../store/actions';

import { useDispatch } from 'react-redux';

function PackageInfo() {
  const dispatch = useDispatch();

  const selectServiceDHL = [
    {
      key: 'DHL NATIONAL',
      product: 'V01PAK',
      accountNumber: '50963405700102',
    },

    {
      key: 'DHL INTERNATIONAL',
      product: 'V53WPAK',
      accountNumber: '50963405705304',
    },
  ];

  const [formData, setFormData] = React.useState({
    weight: '5',
    length_cm: '60',
    width_cm: '30',
    height_cm: '15',
    accountNumber: '50963405700102',
    product: 'V01PAK',
  });

  const [selectedService, setSelectedService] = React.useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeSelect = (event) => {
    const index = event.target.value;
    let selectedServiceb = selectServiceDHL[index];
    setSelectedService(index);
    delete selectedServiceb['key'];

    setFormData({
      ...formData,
      ...selectedServiceb,
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginLeft: '5%',
      }}
    >
      <FormControl sx={{ m: 1, maxWidth: 350 }}>
        <InputLabel id="dhl-select-input-label">DHL Service</InputLabel>
        <Select
          labelId="dhl-service-selector"
          id="dhl-service-select"
          value={selectedService}
          variant="standard"
          label="DHL Service"
          onChange={handleChangeSelect}
        >
          {selectServiceDHL.map((item, index) => {
            return (
              <MenuItem key={index} value={index}>
                {item['key']}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>Please, select the right DHL Service.</FormHelperText>
      </FormControl>
      <FormControl sx={{ my: 2, maxWidth: 350 }}>
        <TextField
          sx={{ mb: 2 }}
          name="weight"
          id="ups-package-weight"
          label="Weight"
          value={formData.weight}
          InputProps={{
            startAdornment: <InputAdornment position="start">KG</InputAdornment>,
          }}
          onChange={handleChange}
        />
        <FormControl sx={{ my: 2, maxWidth: 350 }}>
          <Box>
            <TextField
              sx={{ width: 150 }}
              id="dhl-package-length_cm"
              name="length_cm"
              label="Length"
              value={formData.length_cm}
              InputProps={{
                startAdornment: <InputAdornment position="start">CM</InputAdornment>,
              }}
              onChange={handleChange}
            />
            <TextField
              sx={{ width: 100 }}
              id="dhl-package-width_cm"
              name="width_cm"
              label="Width"
              value={formData.width_cm}
              onChange={handleChange}
            />
            <TextField
              sx={{ width: 100 }}
              id="dhl-package-height_cm"
              name="height_cm"
              label="Height"
              value={formData.height_cm}
              onChange={handleChange}
            />
          </Box>
        </FormControl>
      </FormControl>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <ButtonGroup size="small">
          <Button
            color="error"
            onClick={() => dispatch(actions.SHIPMENT_MODAL.backStep())}
          >
            Back
          </Button>
          <Button
            color="success"
            onClick={() => {
              dispatch(actions.SHIPMENT_MODAL.addShipmentDetails(formData));
              dispatch(actions.SHIPMENT_MODAL.nextStep());
              dispatch(actions.SHIPMENT_MODAL.postShipment());
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default PackageInfo;
