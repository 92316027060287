import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import Flags from '../Flags';
import CardActionsIcons from './CardActionsIcons';
import useResponsive from 'src/hooks/useResponsive';
import { checkClassification } from 'src/utils/rmasUtils';

const styles = {
  cardHovered: {
    transform: 'scale(1.05)',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
  },
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CustomerLayout({ id, attributes, debitor }) {
  // const [expanded, setExpanded] = React.useState(false);
  const mdUp = useResponsive('up', 'md');
  const [isHovered, setIsHovered] = React.useState(false);
  const navigate = useNavigate();

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };



  return (
    <Card
      elevation={7}
      sx={{
        minWidth: { xs: '80%', sm: '90%' },
        maxWidth: { xs: '80%', sm: '90%' },
        border: `2px solid ${checkClassification(String(attributes.classification))}`,
        transition: 'all 0.3s ease-in-out',
        ...(isHovered && styles.cardHovered),
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Stack
        spacing={1}
        flexDirection={'row'}
        justifyContent="center"
        alignItems="center"
      >
        <CardActionArea onClick={() => navigate('/customer/' + id)}>
          <CardHeader
            sx={{ p: 1 }}
            avatar={
              <Avatar
                sx={{
                  borderRadius: 1,
                  bgcolor: checkClassification(String(attributes.classification)),
                  color: 'primary',
                  textShadow: '2px 2px 4px black',
                  boxShadow: `3px 3px 12px ${checkClassification(
                    String(attributes.classification)
                  )}`,
                }}
                aria-label="recipe"
              >
                {id}
              </Avatar>
            }
            title={attributes.customer}
            subheader={
              <Stack spacing={1}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="caption">{attributes.salesperson}</Typography>
                  <Typography variant="caption">{attributes.customerCode}</Typography>
                </Box>
                <Box
                  sx={{
                    bgcolor: 'black',
                    borderRadius: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '140px',
                  }}
                >
                  <Box
                    sx={{
                      mx: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Flags flag={attributes.customerCountry} />
                    <Typography
                      sx={{ ml: 0.5 }}
                      variant="body2"
                      fontWeight={'light'}
                      color="white"
                    >
                      {attributes.customerCountry}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            }
          />
        </CardActionArea>
        <Box
          sx={{
            p: 1,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: 2,
          }}
        >
          <CardActionsIcons customerId={id} />
          <Box sx={{ mx: 2 }} />
        </Box>
      </Stack>
    </Card>
  );
}
