import { createSlice } from '@reduxjs/toolkit'
import { getCustomer } from './asyncCustomer'

const initialState = {
    isLoading: true,
    isError: false,
    data: {},
    isEditMode: false
}


export const CUSTOMER_SLICE = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        changeEditMode: (state, { payload }) => {
            state.isEditMode = !state.isEditMode
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCustomer.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
            })
            .addCase(getCustomer.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data
            })
            .addCase(getCustomer.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true
            })
    },
})

// Action creators are generated for each case reducer function
export const { changeEditMode } = CUSTOMER_SLICE.actions

export default CUSTOMER_SLICE.reducer