import { createSlice } from '@reduxjs/toolkit';
import { getReport } from './asyncReport';

const initialState = {
  isLoading: false,
  isError: null,
  reload: 0,
  data: [],
};

export const REPORT_SLICE = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReport.pending, (state) => {
        state.isLoading = false;
        state.isError = null;
      })
      .addCase(getReport.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload;
      })
      .addCase(getReport.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = REPORT_SLICE.actions;

export default REPORT_SLICE.reducer;
