import { Stack, Typography, Divider, Tooltip } from '@mui/material';
import React from 'react';

function BPInfo({ boxNo, paletteNo }) {
  return (
    <Stack flexDirection={'column'}>
      <Tooltip title="Box No">
        <Typography>B: {boxNo}</Typography>
      </Tooltip>
      <Divider />
      <Tooltip title="Palette No">
        <Typography>P: {paletteNo}</Typography>
      </Tooltip>
    </Stack>
  );
}

export default BPInfo;
