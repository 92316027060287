/* eslint-disable default-case */
export function getRoleString() {
  if (localStorage.getItem('isAdmin') === 'true') {
    return 'Administrator';
  } else if (localStorage.getItem('isModerator') === 'true') {
    return 'Moderator';
  } else {
    return 'User';
  }
}
