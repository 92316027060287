import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getProfileImage, storeLogin } from 'src/utils/loginUtils';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import actions from 'src/store/actions';

function CallbackPage() {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    dispatch(actions.ACCOUNT.getDataFromSessionID(searchParams))
      .unwrap()
      .then(async (res) => {
        storeLogin(res, () => setSearchParams(''));
        await getProfileImage();
        if (sessionStorage.getItem('redirect')) {
          setTimeout(() => {
            navigate(sessionStorage.getItem('redirect'));
            sessionStorage.removeItem('redirect');
          }, 200);

          enqueueSnackbar(`SSO TOKEN REFRESHED!`, {
            variant: 'success',
          });
        } else {
          setTimeout(() => navigate('/'), 200);
          enqueueSnackbar(`SSO SUCCESS!`, {
            variant: 'success',
          });
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(`SSO FAILED!`, {
          variant: 'error',
        });
        setTimeout(() => navigate('/'), 200);
      });
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Redirecting...</h1>
      <svg width="100" height="100">
        <circle cx="50" cy="50" r="40" stroke="black" strokeWidth="3" fill="green" />
      </svg>
      <p>Redirecting to the homepage</p>
    </div>
  );
}

export default CallbackPage;
