import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function RMARow({ attributes, customerID, id }) {
  const navigate = useNavigate();
  const { number, createdAt, status, articles } = attributes;
  const articlesLength = articles.data.length;

  return (
    <Stack
      onClick={() => navigate(`/customer/${customerID}/rma/${id}`)}
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(10px)',
        borderRadius: '15px',
        padding: '20px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s ease-in-out',
        ':hover': {
          boxShadow: ' 0px 4px 15px rgba(0, 0, 0, 0.2)',
        },
      }}
      divider={<Divider />}
    >
      <Stack>
        <Typography variant="subtitle2">{number}</Typography>
        <Typography variant="caption">{`📆${createdAt.split('T')[0]} 🕒${createdAt
          .split('T')[1]
          .split(':')
          .slice(0, 2)
          .join(':')}`}</Typography>
      </Stack>
      <Typography variant="subtitle2">Articles: {articlesLength}</Typography>
    </Stack>
  );
}

export default RMARow;
