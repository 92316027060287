import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import ZotacLayout from './layout/zotac';
import CleanLayout from './layout/cleanLayout/CleanLayout';
// //
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import CustomersPage from './pages/CUSTOMERS/CustomersPage';
import RmasPage from './pages/RMAS/RmasPage';
import RmaPage from './pages/RMA/RmaPage';
import WareHousePage from './pages/WAREHOUSE/WareHousePage';
import CustomerPage from './pages/CUSTOMER/CustomerPage';
import CallbackPage from './pages/CALLBACK/CallbackPage';
import ArticlePage from './pages/ARTICLE/ArticlePage';
import LogoutPage from './pages/LOGOUT/LogoutPage';
import AccountPage from './pages/ACCOUNT/AccountPage';
import NotificationsPage from './pages/NOTIFICATIONS/NotificationsPage';
import DashboardPage from './pages/DASHBOARD/DashboardPage';
import ReportsPage from './pages/REPORTS/ReportsPage';
import NewCustomer from './pages/NEWCUSTOMER/NewCustomer';
import DBZotac from './pages/DBZOTAC/DBZotac';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <ZotacLayout />,
      children: [
        { element: <Navigate to="/customers" />, index: true },
        { path: 'dashboard', element: <DashboardPage /> },
        { path: 'customers', element: <CustomersPage /> },
        { path: 'customer/:id', element: <CustomerPage /> },
        { path: 'customer/:id/rma/:idRma', element: <RmaPage /> },
        { path: 'rmas', element: <RmasPage /> },
        { path: 'warehouse', element: <WareHousePage /> },
        { path: 'account', element: <AccountPage /> },
        { path: 'notifications', element: <NotificationsPage /> },
        { path: 'reports', element: <ReportsPage /> },
        { path: 'new-customer', element: <NewCustomer /> },
        { path: 'db-zotac', element: <DBZotac /> },

        // { path: 'article/:id', element: <ArticlePage /> },
      ],
    },
    {
      path: '/auth',
      children: [
        { element: <Navigate to="/auth/login" />, index: true },
        { path: 'login', element: <LoginPage /> },
        { path: 'callback', element: <CallbackPage /> },
        { path: 'logout', element: <LogoutPage /> },
      ],
    },
    {
      element: <CleanLayout />,
      children: [
        { element: <Navigate to="/customers/" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
  ]);

  return routes;
}
