import { getAllCustomers } from './customers/asyncCustomers';
import { getCustomer } from './customer/asyncCustomer';
import { getRMA, putRMA, putARTICLE } from './rma/asyncRma';
import { getAllWarehouse } from './warehouse/asyncWarehouse';
import { getAllRmas, getFilteredRmas, getFilteredArticle } from './rmas/asyncRmas';
import { ssoLogin, localLogin, getDataFromSessionID } from './account/asyncAccount';
import { addArticlesRMA, removeArticleRMA } from './article/article';
import { getArticle, postXLSX } from './article/asyncArticle';
import { changeEditMode } from './customer/customer';
import { getFilteredRma } from './filteredRma/asyncFilteredRma';
import {
  openModal,
  backStep,
  nextStep,
  addItems,
  resetItems,
  removeItem,
  addDeliverynoteDetails,
  addShipmentData,
  addShipmentDetails,
  resetShipment,
} from './shipmentModal/shipmentModalSlice';
import { postShipment } from './shipmentModal/asyncShipmentModal';
import {
  getDashboardAccepted,
  getDashboardArrived,
  getDashboardTesting,
} from './dashboard/asyncDashboard';
import { getReport } from './report/asyncReport';

const CUSTOMERS = { getAllCustomers, getCustomer, changeEditMode };
const RMA = {
  getRMA,
  putRMA,
  getAllRmas,
  getFilteredRma,
  getFilteredRmas,
  getFilteredArticle,
};
const ARTICLE = { getArticle, addArticlesRMA, removeArticleRMA, postXLSX, putARTICLE };
const WAREHOUSE = { getAllWarehouse };
const ACCOUNT = { ssoLogin, localLogin, getDataFromSessionID };
const SHIPMENT_MODAL = {
  openModal,
  nextStep,
  backStep,
  addItems,
  resetItems,
  removeItem,
  addDeliverynoteDetails,
  addShipmentData,
  addShipmentDetails,
  postShipment,
  resetShipment,
};
const DASHBOARD = { getDashboardAccepted, getDashboardArrived, getDashboardTesting };
const REPORT = { getReport };

// --------------------------------------
const actions = {
  ACCOUNT,
  ARTICLE,
  CUSTOMERS,
  RMA,
  WAREHOUSE,
  SHIPMENT_MODAL,
  DASHBOARD,
  REPORT,
};

export default actions;
