import { createSlice } from '@reduxjs/toolkit'
import { getAllWarehouse } from './asyncWarehouse'

const initialState = {
    isLoading: false,
    isError: null,
    data: []
}

export const WAREHOUSE_SLICE = createSlice({
    name: 'warehouse',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllWarehouse.pending, (state) => {
                state.isLoading = true;
                state.isError = null;
            })
            .addCase(getAllWarehouse.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
            })
            .addCase(getAllWarehouse.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true
            });
    },
})

// Action creators are generated for each case reducer function
export const { } = WAREHOUSE_SLICE.actions

export default WAREHOUSE_SLICE.reducer