import env from '../../env';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { recallLoginSSO } from 'src/utils/loginUtils';

//Async Functions
export const getReport = createAsyncThunk('report/getReport', async (arg) => {

  const res = await axios
    .get(`${env.API_URL_PREFIX}/report/${arg.fromDate}/${arg.toDate}`, {
      headers: {
        jwt: localStorage.getItem('microsoftJWT'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      recallLoginSSO();
      return err.response;
    });
  return res;
});
