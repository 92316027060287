import { createSlice } from '@reduxjs/toolkit';
import { getRMA, putRMA, putARTICLE } from './asyncRma';

const initialState = {
  isLoading: true,
  isError: null,
  reload: 0,
  data: {},
};

export const RMA_SLICE = createSlice({
  name: 'rma',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRMA.pending, (state) => {
        if (state.reload === 0) {
          state.isLoading = true;
        }
        state.isError = null;
      })
      .addCase(getRMA.fulfilled, (state, { payload }) => {
        if (state.reload === 0) {
          state.isLoading = false;
        }
        state.data = payload;
      })
      .addCase(getRMA.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(putRMA.pending, (state) => {
        state.isError = null;
      })
      .addCase(putRMA.fulfilled, (state, { payload }) => {
        state.reload = state.reload + 1;
      })
      .addCase(putRMA.rejected, (state) => {
        state.isError = true;
      })
      .addCase(putARTICLE.pending, (state) => {
        state.isError = null;
      })
      .addCase(putARTICLE.fulfilled, (state, { payload }) => {
        state.reload = state.reload + 1;
      })
      .addCase(putARTICLE.rejected, (state) => {
        state.isError = true;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = RMA_SLICE.actions;

export default RMA_SLICE.reducer;
