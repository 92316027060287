import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import StatusTicket from './StatusTicket';

export default function BasicTable({ columns, rows, navigatePath }) {
  const navigate = useNavigate();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              const { name, align } = column;
              return <TableCell align={align ? align : 'left'}>{name}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            console.log(row);
            const { attributes, id } = row;
            const { debitor } = attributes;
            return (
              <TableRow
                onClick={() =>
                  navigate(`/customer/${debitor['data']['id']}${navigatePath}${id}`)
                }
                hover
                key={attributes.customerID}
                sx={{
                  ':hover': {
                    cursor: 'pointer',
                  },
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                {Object.entries(attributes).map((item) => {
                  if (
                    typeof item[1] !== 'object' &&
                    item[0] !== 'updatedAt' &&
                    item[0] !== 'closedAt'
                  ) {
                    if (item[0] === 'status') {
                      return (
                        <TableCell component="th" scope="row">
                          <StatusTicket status={item[1]} />
                        </TableCell>
                      );
                    }
                    if (item[0] === 'createdAt') {
                      return (
                        <TableCell component="th" scope="row">
                          {item[1].split('T')[0]}
                        </TableCell>
                      );
                    }
                    if (item[0] === 'externalReference') {
                      return (
                        <TableCell component="th" scope="row">
                          {String(item[1]).length <= 30
                            ? String(item[1])
                            : `${String(item[1]).substring(0, 30)}....`}
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell component="th" scope="row">
                        {item[1].toUpperCase()}
                      </TableCell>
                    );
                  }
                  return null;
                })}
              </TableRow>
            );
          })}

          {/* return (
              <TableRow
                onClick={() => navigate(`${navigatePath + id}`)}
                hover
                key={attributes.customerID}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {attributes.customerID}
                </TableCell>
                <TableCell align="left">{attributes.customerCode}</TableCell>
                <TableCell align="left">{attributes.customer}</TableCell>
                <TableCell align="center">{attributes.salesperson}</TableCell>
                <TableCell align="left">{attributes.customerCountry}</TableCell>
                <TableCell align="left">{attributes.rmaTerms}</TableCell>
                <TableCell align="left">{attributes.girArragement}</TableCell>
                <TableCell align="left">{attributes.updatedRMACentre}</TableCell>
                <TableCell align="left">{attributes.remarks}</TableCell>
              </TableRow>
            );
          })} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
