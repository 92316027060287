import {
  Paper,
  Button,
  Typography,
  ButtonGroup,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import VerifiedIcon from '@mui/icons-material/Verified';
import WarningIcon from '@mui/icons-material/Warning';
import BlockIcon from '@mui/icons-material/Block';
import FmdBadIcon from '@mui/icons-material/FmdBad';

const ErrorCodeIcons = ({ errorCode }) => {
  const eCode = () => {
    console.log("Called Ecode")
    switch (errorCode) {
      case 'test_ok':
        return <VerifiedIcon />;
      case 'cid':
        return <WarningIcon />;
      case 'not_arrived':
        return <BlockIcon />;
      case 'faulty':
        return <FmdBadIcon />;
      default:
        return null;
    }
  };

  return <Tooltip title={errorCode}>{eCode()}</Tooltip>;
};

function AddArticles() {
  const dispatch = useDispatch();
  const { activeStep } = useSelector((state) => state.shipment_modal);
  const { articles } = useSelector((state) => state.rma.data.attributes);
  const selectedArticles = useSelector((state) => state.shipment_modal.articles);
  const { number } = useSelector((state) => state.rma.data.attributes);

  React.useEffect(() => {
    dispatch(actions.SHIPMENT_MODAL.addDeliverynoteDetails({ rmaNumber: number }));
    dispatch(actions.SHIPMENT_MODAL.resetItems());
  }, []);

  const handleChange = (e, serial, hsn) => {
    if (e.target.checked) {
      dispatch(actions.SHIPMENT_MODAL.addItems({ serial, hsn }));
    } else {
      dispatch(actions.SHIPMENT_MODAL.removeItem(serial));
    }
  };
  return (
    <Paper sx={{ width: 'auto', p: 2 }}>
      {/* ROW ARTICLE */}
      {articles.data && (
        <FormGroup>
          {articles.data.map((item, index) => {
            const { id, attributes } = item;
            const { serial, hsn, newSerial, newHsn, errorCode } = attributes;
            if (newSerial === null) {
              return (
                <FormControlLabel
                  control={<Checkbox onChange={(e) => handleChange(e, serial, hsn)} />}
                  label={
                    <Stack
                      sx={{ gap: 1 }}
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="row"
                    >
                      <Typography>{attributes.serial}</Typography>
                      <ErrorCodeIcons errorCode={errorCode} />
                    </Stack>
                  }
                />
              );
            }
            return (
              <FormControlLabel
                control={
                  <Checkbox onChange={(e) => handleChange(e, newSerial, newHsn)} />
                }
                label={
                  <Stack
                    sx={{ gap: 1 }}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="row"
                  >
                    <Typography>{attributes.newSerial}</Typography>
                    <PublishedWithChangesIcon color="success" fontSize="small" />
                    <ErrorCodeIcons errorCode={errorCode} />
                  </Stack>
                }
              />
            );
          })}
        </FormGroup>
      )}
      {/*  */}
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <ButtonGroup size="small">
          {activeStep !== 0 && (
            <Button
              color="error"
              onClick={() => dispatch(actions.SHIPMENT_MODAL.backStep())}
            >
              Back
            </Button>
          )}
          <Button
            color="success"
            onClick={() => {
              if (selectedArticles.length > 0) {
                dispatch(actions.SHIPMENT_MODAL.nextStep());
              } else {
                window.alert(
                  'Aktion nicht möglich: Mindestens ein Artikel muss ausgewählt sein.'
                );
              }
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </Box>
    </Paper>
  );
}

export default AddArticles;
