import React from 'react';
import { Stack, Box, Typography, Divider, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function DashboardRmaRow({ title, data }) {
  const navigate = useNavigate();
  return (
    <Stack>
      <Box>
        <Typography variant="subtitle2" sx={{ fontSize: 16 }}>
          {title}
        </Typography>
        <Divider sx={{ mt: 0.5, mb: 3 }} />
      </Box>
      <Stack flexDirection={'column'} sx={{ gap: '12px' }}>
        {data.map((item) => {
          const { rmaId, customerId, rmaNumber, articlesLength } = item;

          return (
            <Paper
              sx={{
                cursor: 'pointer',
                p: 2,
                '&:hover': {
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  borderBottom: 3,
                  borderColor: 'grey', // or 'red' if you don't want to use theme
                  transition: 'all 0.4s ease',
                },
              }}
              onClick={() => navigate(`/customer/${customerId}/rma/${rmaId}`)}
              elevation={4}
            >
              <Stack sx={{ gap: '12px' }} flexDirection={'row'}>
                <Typography>{rmaNumber}</Typography>
                <Divider orientation="vertical" flexItem />
                <Typography>{articlesLength}</Typography>
              </Stack>
            </Paper>
          );
        })}
      </Stack>
    </Stack>
  );
}

export default DashboardRmaRow;
