import DangerousIcon from '@mui/icons-material/Dangerous';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';

export function guaranteeColor(value) {
  switch (value) {
    case 'pending':
      return '#FFE16A';
    case 'allowed':
      return '#AAF27F';
    default:
      return '#FFE7D9';
  }
}

export function guaranteeIcon(value) {
  switch (value) {
    case 'pending':
      return <HourglassBottomIcon />;
    case 'allowed':
      return <VerifiedUserIcon />;
    default:
      return <DangerousIcon />;
  }
}
