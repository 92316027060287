import { CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';

function Loading({ color }) {
  return (
    <Stack sx={{ p: 1 }} flexDirection={'row'} alignItems={'center'} gap={2}>
      <CircularProgress color={color} />
      <Typography variant="subtitle2">Loading...</Typography>
    </Stack>
  );
}

export default Loading;
