function loopRawData(rawData) {
  console.log(rawData);
  let deviceArr = [];
  let addProcess = false;
  rawData.map((item, indexMain) => {
    if (item.length > 0) {
      if (item[0] === 'No.') {
        addProcess = true;
      } else if (addProcess) {
        if (item.length > 4) deviceArr.push(rawData[indexMain]);
      }
    }
  });
  return deviceArr;
}

module.exports = { loopRawData };
