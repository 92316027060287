import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider, Stack, Box, Paper } from '@mui/material';
//
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AddReactionIcon from '@mui/icons-material/AddReaction';

const buttons = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    icon: <LineAxisIcon fontSize="small" />,
    permission: 'isUser',
  },
  {
    label: 'Customers',
    path: '/customers',
    icon: <SupervisorAccountIcon fontSize="small" />,
    permission: 'isUser',
  },
  {
    label: "Rma's",
    path: '/rmas',
    icon: <ConfirmationNumberIcon fontSize="small" />,
    permission: 'isUser',
  },
  {
    label: 'Warehouse',
    path: '/warehouse',
    icon: <WarehouseIcon fontSize="small" />,
    permission: 'isAdmin',
  },
  {
    label: 'Notifications',
    path: '/notifications',
    icon: <NotificationsIcon fontSize="small" />,
    permission: 'isAdmin',
  },
  {
    label: 'Reports',
    path: '/reports',
    icon: <EqualizerIcon fontSize="small" />,
    permission: 'isAdmin',
  },
  {
    label: 'DB Zotac',
    path: '/db-zotac',
    icon: <AddReactionIcon fontSize="small" />,
    permission: 'isAdmin',
  },
  {
    label: 'Account',
    path: '/account',
    icon: <AssignmentIndIcon fontSize="small" />,
    permission: 'isUser',
  },
  {
    label: 'New Customer',
    path: '/new-customer',
    icon: <AddReactionIcon fontSize="small" />,
    permission: 'isAdmin',
  },
];

export default function ScrollableTabsButtonPrevent() {
  const navigate = useNavigate();
  const urlPath = useLocation();

  return (
    <Box sx={{ mt: 4, borderRadius: 6 }}>
      {buttons.map((item) => {
        const { label, path, icon, permission } = item;

        if (permission === 'isAdmin') {
          if (localStorage.getItem('isAdmin') === 'true') {
            return (
              <Box
                onClick={() => navigate(path)}
                sx={{
                  p: 1,
                  my: 1,
                  borderRadius: '8px',
                  backgroundColor: urlPath['pathname'] === path ? 'lightgrey' : '',
                  ':hover': {
                    cursor: 'pointer',
                    backgroundColor: 'lightgrey',
                  },
                }}
              >
                <Stack spacing={1} alignItems={'center'} direction="row">
                  {icon}
                  <Typography>{label}</Typography>
                </Stack>
                <Divider />
              </Box>
            );
          } else {
            return null;
          }
        }

        return (
          <Box
            onClick={() => navigate(path)}
            sx={{
              p: 1,
              my: 1,
              borderRadius: '8px',
              backgroundColor: urlPath['pathname'] === path ? 'lightgrey' : '',
              ':hover': {
                cursor: 'pointer',
                backgroundColor: 'lightgrey',
              },
            }}
          >
            <Stack spacing={1} alignItems={'center'} direction="row">
              {icon}
              <Typography>{label}</Typography>
            </Stack>
            <Divider />
          </Box>
        );
      })}
    </Box>
  );
}
