import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const ReportTable = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>RMA Number</TableCell>
            <TableCell align="right">Articles Length</TableCell>
            <TableCell align="right">Tracking Number</TableCell>
            <TableCell align="right">Country Code</TableCell>
            <TableCell align="right">Closed Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.rmaNumber}>
              <TableCell component="th" scope="row">
                {row.rmaNumber}
              </TableCell>
              <TableCell align="right">{row.articlesLength}</TableCell>
              <TableCell align="right">{row.trackingnr}</TableCell>
              <TableCell align="right">{row.countryCode}</TableCell>
              <TableCell align="right">{row.closedAt}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportTable;
