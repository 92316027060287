import { createSlice } from '@reduxjs/toolkit';
import { getAllRmas, getFilteredRmas, getFilteredArticle } from './asyncRmas';

const initialState = {
  isLoading: false,
  isError: null,
  data: [],
  articlesSearch: [],
  criteria: '',
  meta: {},
};

export const RMAS_SLICE = createSlice({
  name: 'rmas',
  initialState,
  reducers: {
    setCriteriaRedux: (state, { payload }) => {
      state.criteria = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRmas.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(getAllRmas.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.articlesSearch = []
      })
      .addCase(getAllRmas.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getFilteredRmas.pending, (state) => {
        state.isError = null;
      })
      .addCase(getFilteredRmas.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
        state.meta = action.payload.meta;
        state.articlesSearch = []
      })
      .addCase(getFilteredRmas.rejected, (state, action) => {
        state.isError = true;
      })
      .addCase(getFilteredArticle.pending, (state) => {
        state.isError = null;
        state.isLoading = true;
      })
      .addCase(getFilteredArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.articlesSearch = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(getFilteredArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setCriteriaRedux } = RMAS_SLICE.actions;

export default RMAS_SLICE.reducer;
