import React from 'react';
import { Paper, Stack, Typography, Divider, Button } from '@mui/material';
import Loading from 'src/components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions';
import ReportTable from 'src/components/ReportTable';
import ExportToExcel from 'src/components/ExportToExcel';

function ReportsPage() {
  const dispatch = useDispatch();
  const { isLoading, isError, data } = useSelector((state) => state.report);

  const todayDate = new Date().toISOString().split('T')[0];
  const day = new Date().toISOString().split('T')[0].split('-');
  const [fromDate, setFromDate] = React.useState(`${day[0]}-${day[1]}-01`);
  const [toDate, setToDate] = React.useState(todayDate);

  if (isLoading) return <Loading color="success" />;
  if (isError) return <>{'error'}</>;

  return (
    <Paper sx={{ p: 2 }}>
      <Stack>
        <Typography variant="subtitle2" sx={{ fontSize: 16 }}>
          Report
        </Typography>
        <Divider sx={{ mt: 0.5, mb: 3 }} />
        <Stack sx={{ flexDirection: 'row', gap: '12px', justifyContent: 'center' }}>
          <label for="dateInputFrom">FROM:</label>
          <input
            id="dateInputFrom"
            name="dateInputFrom"
            value={fromDate}
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
            type="date"
          />
          <label for="dateInputTo">TO:</label>
          <input
            id="dateInputTo"
            name="dateInputTo"
            value={toDate}
            onChange={(e) => {
              setToDate(e.target.value);
            }}
            type="date"
          />
          <Button
            variant="outlined"
            size="small"
            onClick={() => dispatch(actions.REPORT.getReport({ fromDate, toDate }))}
          >
            Generate
          </Button>
          {data.length > 0 && (
            <ExportToExcel data={data} fileName={`Report-Zotac.${fromDate}-${toDate}`} />
          )}
        </Stack>
        <Divider sx={{ my: 3 }} />
        {data.length > 0 && <ReportTable data={data} />}
      </Stack>
    </Paper>
  );
}

export default ReportsPage;
