function generateRMANumber(type, customerCode, zammadTicket, rmasLength) {

    // Return a Length of the RMAS for the Debitor
    const tempRmasLength = rmasLength.length

    // Calculate Date
    const todayDate = new Date().toISOString()
    const tempDate = todayDate.split("T")[0].replace("-", "")
    const finalDate = tempDate.replace("-", "")

    if (type === "vga") {
        const rmaNumberTemp = "V"

        return `${rmaNumberTemp}${finalDate}${tempRmasLength}${customerCode}_${zammadTicket}`
    } else if (type === "box") {
        const rmaNumberTemp = "M"

        return `${rmaNumberTemp}${finalDate}${tempRmasLength}${customerCode}_${zammadTicket}`

    }

}





module.exports = { generateRMANumber }