import env from '../../env';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import actions from '../actions';
import { recallLoginSSO } from 'src/utils/loginUtils';

//Async Functions
export const getAllCustomers = createAsyncThunk(
  'customers/getAllCustomers',
  async (arg, thunkAPI) => {
    arg = arg === undefined ? { criteria: 'customerCode', searchValue: '' } : arg;
    let { criteria, searchValue } = arg;
    searchValue = searchValue === undefined ? '' : searchValue;
    console.log(criteria);
    criteria = criteria === '' ? 'customerCode' : criteria;

    const res = await axios
      .get(
        `${env.API_URL}/debitors/?filters[${criteria}][$contains]=${searchValue}&pagination[limit]=-1`,
        {
          headers: {
            jwt: localStorage.getItem('microsoftJWT'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((data) => data.data)
      .catch((err) => {
        recallLoginSSO();
        return err.response;
      });
    return res;
  }
);
