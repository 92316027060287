import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import actions from 'src/store/actions';
import {
  Container,
  Divider,
  Paper,
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
  Box,
  InputBase,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LockIcon from '@mui/icons-material/Lock';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';

import { useSnackbar } from 'notistack';
//
import { green, orange, red, grey } from '@mui/material/colors';
import ArticleAccordion from 'src/components/ArticleAccordion';
import ConversationRow from 'src/components/ConversationRow';
import TypeShower from 'src/components/TypeShower';
import StatusStepper from 'src/components/StatusStepper';
import Loading from 'src/components/Loading';
import ShipmentModal from 'src/components/ShipmentModal/ShipmentModal';
import ShippedBar from 'src/components/ShippedBar';
import {
  checkClassification,
  loopArticleReturnStatus,
  errorCodeCheckDone,
} from 'src/utils/rmasUtils';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SaveIcon from '@mui/icons-material/Save';

const handleClick = (
  dispatchAction,
  snackbar,
  windowMessage,
  successSnackbarMessage,
  callbackDone,
  callbackError
) => {
  if (window.confirm(windowMessage)) {
    dispatchAction()
      .unwrap()
      .then((res) => {
        snackbar(`${successSnackbarMessage}`, {
          variant: 'success',
        });
        if (callbackDone) callbackDone();
      });
  }
};

function RmaPage() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const params = useParams();

  const id = params['idRma'];
  const { isLoading, isError, data, reload } = useSelector((state) => state.rma);
  const [inputExternalReference, setInputExternalReference] = React.useState('');
  const imageUploadRef = React.useRef(null);
  const [message, setMessage] = React.useState('');

  React.useEffect(() => {
    dispatch(actions.RMA.getRMA(id));
  }, [reload]);

  if (isLoading) return <Loading color="success" />;
  if (isError) return <p>isError</p>;

  const { attributes } = data;

  const idDebitor = attributes?.debitor?.data?.id;
  const nameDebitor = attributes?.debitor?.data?.attributes?.customerCode;

  const status = attributes?.status;
  const number = attributes?.number;
  const solvedObj = attributes?.solvedObj;
  const history = attributes?.history;
  const classification = attributes?.debitor?.data?.attributes?.classification;
  const externalReference = attributes?.externalReference;

  function checkRMANumberType(value) {
    const trim = value[0];
    if (trim === 'V') {
      return 'GPU';
    } else {
      return 'Barebone';
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('File selected:', file.name);
      // Handle the file upload process here
    }
  };

  const articlesStatus = loopArticleReturnStatus(attributes?.articles?.data);

  return (
    <Stack spacing={2}>
      <Paper sx={{ p: 2 }}>
        <ShipmentModal />
        <Stack>
          <Box>
            <Typography variant="subtitle2" sx={{ fontSize: 16 }}>
              Request Information
            </Typography>
            <Divider sx={{ mt: 0.5, mb: 3 }} />
          </Box>
          <Stack
            sx={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <Box sx={{ mb: 3 }}>
              <Box>
                <Typography variant="caption">RMA Number</Typography>
                <Typography
                  variant="h5"
                  sx={{ textDecoration: 'underline', fontWeight: '400', mb: 1 }}
                >
                  {attributes.number}
                </Typography>
              </Box>
              {externalReference !== null ? (
                <Box>
                  <Typography variant="caption">External Reference</Typography>
                  <Typography>{String(externalReference).toUpperCase()}</Typography>
                </Box>
              ) : (
                <Paper
                  elevation="4"
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
                >
                  <InputBase
                    value={inputExternalReference}
                    onChange={(e) => {
                      setInputExternalReference(e.currentTarget.value);
                    }}
                    sx={{ flex: 1 }}
                    placeholder="External Reference"
                  />
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                  <IconButton
                    onClick={() => {
                      handleClick(
                        () =>
                          dispatch(
                            actions.RMA.putRMA({
                              rmaID: id,
                              updateData: { externalReference: inputExternalReference },
                            })
                          ),
                        enqueueSnackbar,
                        'Die eingegebene External Reference Speichern?',
                        'Die External Reference würde erfolgreich gespeichert.'
                      );
                      setInputExternalReference('');
                    }}
                    type="button"
                    sx={{ p: '10px' }}
                    aria-label="search"
                  >
                    <SaveIcon />
                  </IconButton>
                </Paper>
              )}
            </Box>
            <Box>
              <Box sx={{ mb: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => navigate(`/customer/${idDebitor}`)}
                >
                  🗣️{nameDebitor}
                </Button>
              </Box>
              <Box
                sx={{
                  p: 0.5,
                  borderRadius: '6px',
                  bgcolor: checkClassification(String(classification)),
                  color: 'primary',
                  textShadow: '2px 2px 4px white',
                  boxShadow: `3px 3px 12px ${checkClassification(
                    String(classification)
                  )}`,
                  mb: 1,
                }}
              >
                <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {String(classification).toUpperCase()}
                </Typography>
              </Box>
              <TypeShower type={checkRMANumberType(attributes.number)} />
            </Box>
          </Stack>
          <ArticleAccordion />
          <Stack sx={{ mt: 2 }} flexDirection={'row'} justifyContent={'flex-end'} gap={2}>
            {/* ACCEPT RMA Action BTN */}
            {status === 'pending' &&
              (articlesStatus === 'wait' ? null : articlesStatus === 'accept' ? (
                <Button
                  onClick={() => {
                    handleClick(
                      () =>
                        dispatch(
                          actions.RMA.putRMA({
                            rmaID: id,
                            updateData: { status: 'accepted' },
                          })
                        ),
                      enqueueSnackbar,
                      'RMA status auf Accepted setzen?',
                      'Diese RMA würde Akzeptiert.'
                    );
                  }}
                  sx={{
                    bgcolor: green[600],
                    color: 'white',
                    ':hover': {
                      bgcolor: '#229A16',
                      color: 'black',
                    },
                  }}
                  startIcon={<CheckIcon />}
                  size="small"
                  variant="contained"
                >
                  ACCEPT RMA
                </Button>
              ) : articlesStatus === 'reject' ? (
                <Button
                  sx={{
                    bgcolor: red[400],
                    color: 'white',
                    ':hover': {
                      bgcolor: red[600],
                      color: 'black',
                    },
                  }}
                  startIcon={<DeleteSweepIcon />}
                  size="small"
                  variant="contained"
                  onClick={() => {
                    handleClick(
                      () =>
                        dispatch(
                          actions.RMA.putRMA({
                            rmaID: id,
                            updateData: { status: 'rejected' },
                          })
                        ),
                      enqueueSnackbar,
                      'RMA status auf Reject setzen?',
                      'Sie haben das RMA status geupdated.'
                    );
                  }}
                >
                  REJECT RMA
                </Button>
              ) : null)}
            {/* Arrived ACTION BTN */}
            {status === 'accepted' && (
              <Button
                onClick={() => {
                  handleClick(
                    () =>
                      dispatch(
                        actions.RMA.putRMA({
                          rmaID: id,
                          updateData: { status: 'shipped' },
                        })
                      ),
                    enqueueSnackbar,
                    'RMA status auf Arrived setzen?',
                    'Sie haben das RMA status geupdated.'
                  );
                }}
                sx={{
                  bgcolor: orange[400],
                  color: 'white',
                  ':hover': {
                    bgcolor: orange[600],
                    color: 'black',
                  },
                }}
                startIcon={<MoveToInboxIcon />}
                size="small"
                variant="contained"
              >
                ARRIVED
              </Button>
            )}
            {/* Testing ACTION BTN */}
            {status === 'shipped' && (
              <Button
                onClick={() => {
                  handleClick(
                    () =>
                      dispatch(
                        actions.RMA.putRMA({
                          rmaID: id,
                          updateData: { status: 'testing' },
                        })
                      ),
                    enqueueSnackbar,
                    'RMA status auf Testing setzen?',
                    'Sie haben das RMA status geupdated.'
                  );
                }}
                sx={{
                  bgcolor: grey[400],
                  color: 'black',
                  ':hover': {
                    bgcolor: grey[600],
                    color: 'black',
                  },
                }}
                startIcon={<BugReportOutlinedIcon />}
                size="small"
                variant="contained"
              >
                TESTING
              </Button>
            )}
            {/* Check Done ACTION BTN */}
            {status === 'testing' &&
              (errorCodeCheckDone(
                attributes?.articles?.data,
                attributes?.debitor?.data?.attributes?.classification
              ) ? (
                <Button
                  onClick={() => {
                    handleClick(
                      () =>
                        dispatch(
                          actions.RMA.putRMA({
                            rmaID: id,
                            updateData: { status: 'verified' },
                          })
                        ),
                      enqueueSnackbar,
                      'RMA status auf Check Done setzen?',
                      'Sie haben das RMA status geupdated.'
                    );
                  }}
                  sx={{
                    bgcolor: grey[400],
                    color: 'black',
                    ':hover': {
                      bgcolor: grey[600],
                      color: 'black',
                    },
                  }}
                  startIcon={<VerifiedUserOutlinedIcon />}
                  size="small"
                  variant="contained"
                >
                  CHECK DONE
                </Button>
              ) : null)}
            {/* shipment ACTION BTN */}
            {status === 'verified' && (
              <Button
                onClick={() => {
                  dispatch(actions.SHIPMENT_MODAL.openModal());
                  // handleClick(
                  //   () =>
                  //     dispatch(
                  //       actions.RMA.putRMA({
                  //         rmaID: id,
                  //         updateData: { status: 'shipped' },
                  //       })
                  //     ),
                  //   enqueueSnackbar,
                  //   'RMA status auf Arrived setzen?',
                  //   'Sie haben das RMA status geupdated.'
                  // );
                }}
                sx={{
                  bgcolor: grey[400],
                  color: 'black',
                  ':hover': {
                    bgcolor: grey[600],
                    color: 'black',
                  },
                }}
                startIcon={<LocalShippingOutlinedIcon />}
                size="small"
                variant="contained"
              >
                SHIPMENT
              </Button>
            )}
            {/* Reject RMA BTN */}
            {/* {status === 'open' && (
              <Button
                sx={{
                  bgcolor: red[400],
                  color: 'white',
                  ':hover': {
                    bgcolor: red[600],
                    color: 'black',
                  },
                }}
                startIcon={<DeleteSweepIcon />}
                size="small"
                variant="contained"
              >
                REJECT RMA
              </Button>
            )} */}
            {status !== 'pending' &&
              status !== 'open' &&
              status !== 'close' &&
              status !== 'rejected' &&
              status !== 'testing' &&
              errorCodeCheckDone(attributes?.articles?.data) && (
                <Button
                  onClick={() => {
                    handleClick(
                      () =>
                        dispatch(
                          actions.RMA.putRMA({
                            rmaID: id,
                            updateData: { status: 'close' },
                          })
                        ),
                      enqueueSnackbar,
                      'RMA status auf Closed setzen?',
                      'Sie haben das RMA status geupdated.'
                    );
                  }}
                  sx={{
                    bgcolor: red[400],
                    color: 'white',
                    ':hover': {
                      bgcolor: red[600],
                      color: 'black',
                    },
                  }}
                  startIcon={<LockIcon />}
                  size="small"
                  variant="contained"
                >
                  CLOSE
                </Button>
              )}
          </Stack>
        </Stack>
      </Paper>
      <Paper sx={{ p: 2 }}>
        <Stack>
          <Typography variant="subtitle2" sx={{ fontSize: 16 }}>
            RMA state
          </Typography>
          <Divider sx={{ mt: 0.5, mb: 3 }} />
          <StatusStepper status={status} />
        </Stack>
      </Paper>
      {status === 'close' && solvedObj !== null && <ShippedBar />}
      <Paper sx={{ mt: 2, p: 2 }}>
        <Typography variant="subtitle2" sx={{ fontSize: 16 }}>
          History & Conversation
        </Typography>
        <Divider sx={{ mt: 0.5, mb: 1 }} />
        <Container
          sx={{
            p: 1,
            display: 'flex',
            gap: 1,
            flexDirection: 'column',
            maxHeight: '32em',
            overflowY: 'auto',
          }}
          maxWidth="100%"
        >
          {history !== null &&
            history.map((item) => {
              const { borderColor, infoText, statusText, username, createdAt } = item;
              return (
                <ConversationRow
                  borderColor={borderColor}
                  infoText={infoText}
                  statusText={statusText}
                  username={username}
                  createdAt={createdAt}
                />
              );
            })}
        </Container>
        <Stack sx={{ mt: 2 }} flexDirection={'row'} justifyContent={'center'}>
          <input
            ref={imageUploadRef}
            onChange={handleFileChange}
            type="file"
            style={{ display: 'none' }}
          />
          <Button
            startIcon={<AttachFileIcon />}
            onClick={() => imageUploadRef.current.click()}
            variant="contained"
            size="small"
          />
          <TextField
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
            label="Message"
            fullWidth
          />
          <Button
            onClick={() => {
              handleClick(
                () =>
                  dispatch(
                    actions.RMA.putRMA({
                      rmaID: id,
                      updateData: { message: message },
                    })
                  ),
                enqueueSnackbar,
                'RMA Nachricht Senden??',
                'Nachricht erfolgreich gesendet.',
                setMessage('')
              );
            }}
            size="small"
            variant="contained"
            sx={{ mx: 1 }}
          >
            Send
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
}

export default RmaPage;
