import env from '../../env';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

//Async Functions
export const ssoLogin = createAsyncThunk('account/ssoLogin', async (arg) => {
  const res = await axios
    .get(`${env.REACT_APP_SSO_BACKEND}/auth/me`, {
      headers: {
        jwt: localStorage.getItem('microsoftJWT'),
      },
    })
    .then((data) => {
      console.log(data);
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return err.response;
    });
  return res;
});

//Async Functions
export const localLogin = createAsyncThunk('account/localLogin', async (arg) => {
  const res = await axios
    .post(`${env.REACT_APP_SSO_BACKEND}/auth/local/login`, arg)
    .then((data) => {
      return data.data;
    })
    .catch((err) => {
      console.log(err);
      return err.response;
    });
  return res;
});

export const getDataFromSessionID = createAsyncThunk(
  'account/getDataFromSessionID',
  async (arg) => {
    const res = await axios
      .get(`${env.REACT_APP_SSO_BACKEND}/auth/sso/callback_get_token?${arg}`)
      .then((data) => {
        console.log(data.data);
        return data.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
    return res;
  }
);
