import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  Checkbox,
  Table,
  TableBody,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import actions from 'src/store/actions';

const TableRowTodo = ({ id, name, status }) => {
  const dipatch = useDispatch();

  const handleChange = (name, status) => {
    if (window.confirm('You are Sure?')) {
      let tempObj = {};
      tempObj[name] = status;
      dipatch(
        actions.ARTICLE.putARTICLE({
          articleID: id,
          updateData: tempObj,
        })
      );
    }
  };

  const colorResult = status === 'passed' ? 'green' : 'red';

  return (
    <Table>
      <TableBody>
        <TableRow key={name}>
          <TableCell>{name.toUpperCase()}</TableCell>
          {status === null && (
            <>
              <TableCell align="right">
                <Checkbox color="success" onChange={() => handleChange(name, 'passed')} />
                Pass
              </TableCell>
              <TableCell align="right">
                <Checkbox color="error" onChange={() => handleChange(name, 'failed')} />
                Fail
              </TableCell>
            </>
          )}
          {status !== null && (
            <TableCell>
              <Typography fontWeight={'bold'} color={colorResult}>
                {status.toUpperCase()}
              </Typography>
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};

const BenchmarkList = (props) => {
  const id = props['id'];
  return Object.entries(props).map((item) => {
    let key = item[0];
    let value = item[1];

    if (key === 'id') {
      return null;
    }

    return <TableRowTodo id={id} status={value} name={key} />;
  });
};

export default BenchmarkList;
