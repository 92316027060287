import { TextField, Button, Divider, Stack, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import Loading from 'src/components/Loading';
import env from 'src/env';

function DBZotac() {
  const [serial, setSerial] = React.useState('');
  const [serialData, setSerialData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  const dbCall = async (serial) => {
    setIsLoading(true);
    return axios
      .get(`${env.API_URL_PREFIX}/dbzotac/${serial}`, {
        headers: { jwt: localStorage.getItem('microsoftJWT') },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.data.length > 0) {
          setSerialData(res.data.data[0]);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };

  return (
    <div>
      <Stack flexDirection="row">
        <TextField
          onChange={(e) => {
            setSerial(e.currentTarget.value);
          }}
        />
        <Button variant="outlined" onClick={() => dbCall(serial)}>
          Call
        </Button>
      </Stack>
      <Divider sx={{ my: 2 }} />
      {isLoading && <Loading color="success" />}
      {!isLoading && (
        <Stack>
          {Object.entries(serialData).map((item, index) => {
            const key = item[0];
            const value = item[1];

            return (
              <Stack flexDirection="row" sx={{ gap: '12px' }}>
                <Typography sx={{ fontWeight: 'bold' }}>{key.toUpperCase()}</Typography>
                <Typography>{value}</Typography>
              </Stack>
            );
          })}
        </Stack>
      )}
    </div>
  );
}

export default DBZotac;
