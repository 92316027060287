import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { TableCell, Box, TableHead, TableRow, Paper, Grid } from '@mui/material';
// #
import { checkArrayForText } from 'src/utils/rmasUtils';
import ArticleForm from './ArticleForm';
import Loading from './Loading';
import { guaranteeColor, guaranteeIcon } from 'src/utils/guaranteeUtils';
import OldArticleBox from './OldArticleBox';
import GuaranteeBtnGroup from './GuaranteeBtnGroup';
import BiotechIcon from '@mui/icons-material/Biotech';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import BPInfo from './BPInfo';

function InternalRow({ value, keyObj }) {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <TableRow
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        transition: 'all 0.3s ease-in-out',
        ...(isHovered && {
          transform: 'scale(1.01)',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
        }),
      }}
    >
      <TableCell component="th" scope="row">
        {keyObj[0].toUpperCase() + keyObj.slice(1)}
      </TableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  );
}

export default function ArticleAccordion() {
  const { articles, status } = useSelector((state) => state.rma.data.attributes);
  const { isLoading } = useSelector((state) => state.rma);

  if (isLoading) return <Loading color="success" />;

  return articles?.data.map((article) => {
    const { attributes, id } = article;

    const {
      serial,
      error,
      testedFrom,
      createdAt,
      updatedAt,
      skuNumber,
      invoiceNo,
      invoiceDate,
      problemText,
      errorCode,
      errorDescription,
      newSerial,
      newHsn,
      newSku,
      type,
      hsn,
      guarantee,
      furmark,
      heaven4,
      mark3d,
      boxNo,
      paletteNo,
    } = attributes;

    return (
      <Paper elevation={12}>
        <Accordion>
          <AccordionSummary
            sx={{
              bgcolor: guaranteeColor(guarantee),
              borderBottom: '1.1px solid black',
              '& .MuiAccordionSummary-content': {
                alignItems: 'center',
              },
            }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {guaranteeIcon(String(guarantee))}
            <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
            {errorCode !== null && (
              <>
                <BiotechIcon />
                <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
              </>
            )}
            {newSerial !== null && (
              <>
                <PublishedWithChangesIcon />
                <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
              </>
            )}
            <Typography>Serial: {serial}</Typography>
            <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
            <Typography>HSN: {hsn}</Typography>
            <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
            <Typography>SKU: {skuNumber}</Typography>
            <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
            <Typography>Guarantee: {String(guarantee)}</Typography>
            {status === 'open' || status === 'pending' ? (
              <>
                <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
                <GuaranteeBtnGroup id={id} guarantee={guarantee} />
              </>
            ) : null}
            {boxNo !== null && (
              <>
                <Divider sx={{ mx: 2 }} orientation="vertical" flexItem />
                <BPInfo boxNo={boxNo} paletteNo={paletteNo} />
              </>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid sx={{ p: 2 }} item xs={12} md={6}>
                <ArticleForm
                  newSerial={newSerial}
                  newHsn={newHsn}
                  newSku={newSku}
                  errorCode={errorCode}
                  errorDescription={errorDescription}
                  id={id}
                  guarantee={guarantee}
                  furmark={furmark}
                  heaven4={heaven4}
                  mark3d={mark3d}
                />
              </Grid>
              <Grid sx={{ p: 2 }} item xs={12} md={6}>
                <OldArticleBox attributes={attributes} id={id} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  });
}
