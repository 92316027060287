import { createSlice } from '@reduxjs/toolkit';
import { getFilteredRma } from './asyncFilteredRma';

const initialState = {
  pendingValue: '',
  isLoading: {
    new: false,
    verify: false,
    accepted: false,
    shipment: false,
    error: false,
    check: false,
    done: false,
    close: false,
  },
  isError: [],
  new: [],
  verify: [],
  accepted: [],
  shipment: [],
  error: [],
  check: [],
  done: [],
  close: [],
  meta: {},
};

export const FILTERED_RMAS_SLICE = createSlice({
  name: 'filteredRmas',
  initialState,
  reducers: {
    setPendingValue: (state, action) => {
      state.pendingValue = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilteredRma.pending, (state, { meta }) => {
        state.isLoading[meta.arg.idSlice] = true;
      })
      .addCase(getFilteredRma.fulfilled, (state, { payload }) => {
        state.isLoading[payload.filtered] = false;
        switch (payload.filtered) {
          case 'new':
            state.new = payload.data;
            break;
          case 'verify':
            state.verify = payload.data;
            break;
          case 'accepted':
            state.accepted = payload.data;
            break;
          case 'shipment':
            state.shipment = payload.data;
            break;
          case 'error':
            state.error = payload.data;
            break;
          case 'check':
            state.check = payload.data;
            break;
          case 'close':
            state.close = payload.data;
            break;
          default:
            break;
        }
        // state.data = payload.data;
        // state.meta = payload.meta;
      })
      .addCase(getFilteredRma.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setPendingValue } = FILTERED_RMAS_SLICE.actions;

export default FILTERED_RMAS_SLICE.reducer;
