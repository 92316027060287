import React from 'react';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';

const ExportToExcel = ({ data, fileName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (arrayOfData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(arrayOfData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    const url = URL.createObjectURL(dataBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName + fileExtension);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button size="small" variant="outlined" onClick={(e) => exportToCSV(data, fileName)}>
      Export to Excel
    </Button>
  );
};

export default ExportToExcel;
