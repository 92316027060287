import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
//
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ClearIcon from '@mui/icons-material/Clear';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(90deg, rgba(50,50,50,1) 0%, rgba(255,223,0,1) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient(90deg, rgba(50,50,50,1) 0%, rgba(255,223,0,1) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient(90deg, rgba(50,50,50,1) 50%, rgba(55,50,50,1) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient(90deg, rgba(50,50,50,1) 50%, rgba(55,53,50,1) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: (
      <FiberNewOutlinedIcon
        sx={{ cursor: 'pointer' }}
        onClick={() => console.log('hello')}
      />
    ),
    2: (
      <PendingActionsOutlinedIcon
        sx={{ cursor: 'pointer' }}
        onClick={() => console.log('hello')}
      />
    ),
    3: (
      <CheckCircleOutlinedIcon
        sx={{ cursor: 'pointer' }}
        onClick={() => console.log('hello')}
      />
    ),
    4: <LocalShippingOutlinedIcon />,
    5: <BugReportOutlinedIcon />,
    6: <VerifiedUserOutlinedIcon />,
    7: <LockOutlinedIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  'Submit RMA',
  'Guarentee Check',
  'Accepted',
  'Arrived',
  'Testing',
  'Check Done',
  'Closed',
];

export default function CustomizedSteppers({ status }) {
  function convertStatusToNumber(statusText) {
    switch (statusText) {
      case 'open':
        return 0;
      case 'pending':
        return 1;
      case 'accepted':
        return 2;
      case 'shipped':
        return 3;
      case 'testing':
        return 4;
      case 'verified':
        return 5;
      case 'close':
        return 6;
      default:
        return -1;
    }
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={convertStatusToNumber(status)}
        connector={<ColorlibConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
