import { createSlice } from '@reduxjs/toolkit';
import { getArticle, postXLSX } from './asyncArticle';

const initialState = {
  isLoading: true,
  xlsxIsLoading: false,
  isError: false,
  changes: {},
  data: {},
  xlsxArticles: [],
};

export const ARTICLE_SLICE = createSlice({
  name: 'article',
  initialState,
  reducers: {
    addArticlesRMA: (state, { payload }) => {
      state.xlsxArticles = payload;
    },
    removeArticleRMA: (state, { payload }) => {
      let copy = state.xlsxArticles;
      copy.splice(payload, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArticle.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getArticle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(getArticle.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(postXLSX.pending, (state) => {
        state.xlsxIsLoading = true;
      })
      .addCase(postXLSX.fulfilled, (state, action) => {
        state.xlsxIsLoading = false;
      })
      .addCase(postXLSX.rejected, (state, action) => {
        state.xlsxIsLoading = false;
        // state.isError = true;
      });
  },
});

// Action creators are generated for each case reducer function
export const { addArticlesRMA, removeArticleRMA } = ARTICLE_SLICE.actions;

export default ARTICLE_SLICE.reducer;
