import { createSlice } from '@reduxjs/toolkit';
import { ssoLogin, localLogin, getDataFromSessionID } from './asyncAccount';

const initialState = {
  data: [],
  status: 0,
  statusText: '',
  isLoading: true,
  isError: false,
};

export const ACCOUNT_SLICE = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ssoLogin.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(ssoLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        // if (action.payload === undefined || action.payload.status) {
        //   state.isError = true;
        //   state.status = action.payload.status;
        //   state.statusText = action.payload.statusText;
        //   localStorage.clear();
        // } else {
        //   state.data = action.payload.data;
        //   state.isError = false;
        // }
      })
      .addCase(ssoLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(localLogin.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(localLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload === undefined || action.payload.status !== 200) {
          state.isError = true;
          state.status = action.payload.status;
          state.statusText = action.payload.statusText;
          localStorage.clear();
        } else {
          state.data = action.payload.data;
          state.status = action.payload.status;
          state.isError = false;
        }
      })
      .addCase(localLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getDataFromSessionID.pending, (state) => {
        // state.isLoading = true;
        // state.isError = false;
      })
      .addCase(getDataFromSessionID.fulfilled, (state, action) => {
        // state.isLoading = false;
        // if (action.payload === undefined || action.payload.status) {
        //   state.isError = true;
        //   state.status = action.payload.status;
        //   state.statusText = action.payload.statusText;
        //   localStorage.clear();
        // } else {
        //   state.data = action.payload.data;
        //   state.isError = false;
        // }
      })
      .addCase(getDataFromSessionID.rejected, (state, action) => {
        // state.isLoading = false;
        // state.isError = true;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = ACCOUNT_SLICE.actions;

export default ACCOUNT_SLICE.reducer;
