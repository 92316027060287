import React from 'react';
import { Paper, Stack, Typography, Divider, Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Loading from 'src/components/Loading';

const downloadFile = async (downloadURL) => {
  let fileName = String(downloadURL).split('/')[4];
  try {
    // Make a GET request to fetch the raw PDF file
    const response = await axios.get(downloadURL, {
      headers: {
        jwt: localStorage.getItem('microsoftJWT'),
      },
      responseType: 'blob',
    });

    // Create a Blob object with data and produce a download link (could be assigned to an href attribute)
    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    // Generate download link
    const link = document.createElement('a');
    link.href = fileURL;
    link.setAttribute('download', fileName); // `download` attribute specifies the name for downloaded file
    document.body.appendChild(link);

    // Automatically download the PDF
    link.click();

    // Remove the link from the DOM
    link.parentNode.removeChild(link);

    // Remove the link from the DOM
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};

function ShippedBar() {
  const { isLoading, isError, data, reload } = useSelector((state) => state.rma);

  const { attributes } = data;

  const status = attributes?.status;
  const solvedObj = attributes?.solvedObj;

  if (isLoading) return <Loading color="success" />;
  if (isError) return <p>isError</p>;

  return (
    <Paper sx={{ p: 2 }}>
      <Stack>
        <Typography variant="subtitle2" sx={{ fontSize: 16 }}>
          Shipping Info
        </Typography>
        <Divider sx={{ mt: 0.5, mb: 3 }} />
        <Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography>Tracking Nr:</Typography>
            <Typography>{solvedObj?.trackingNumber}</Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Stack
            sx={{ gap: 2 }}
            justifyContent={'center'}
            flexDirection={'row'}
            flexWrap={'wrap'}
          >
            <Box>
              <Typography>Shipping Date:</Typography>
              <Typography>{solvedObj?.shippingDate}</Typography>
            </Box>
            <Box>
              <Typography>Provider:</Typography>
              <Typography>{solvedObj?.shipmentProvider}</Typography>
            </Box>
          </Stack>
          <Divider sx={{ my: 1 }} />

          <Stack sx={{ gap: 2 }}>
            <Button
              color="primary"
              onClick={async () => {
                await downloadFile(solvedObj?.labelUrl);
              }}
              variant="outlined"
            >
              Download Label
            </Button>
            <Button
              onClick={async () => {
                await downloadFile(solvedObj?.deliveryNoteUrl);
              }}
              color="primary"
              variant="outlined"
            >
              Download Deliverynote
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
}

export default ShippedBar;
