import React from 'react';
import { Stack } from '@mui/material';
import RMARow from './RMARow';
import RMAHeader from './RMAHeader';

function RMAContainer({ columns, rows, navigatePath }) {
  const stateTicket = [
    { name: 'OPEN', status: 'open' },
    { name: 'PENDING', status: 'pending' },
    { name: 'CLOSED', status: 'close' },
  ];
  return (
    <Stack>
      <RMAHeader columns={columns} />
      {rows.map((item, index) => {
        const { attributes, id } = item;
        console.log(item);
        return (
          <RMARow
            navigatePath={navigatePath}
            key={index + 'RMARow'}
            attributes={attributes}
            idRow={id}
          />
        );
      })}
    </Stack>
  );
}

export default RMAContainer;
