import env from '../../env';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { recallLoginSSO } from 'src/utils/loginUtils';

//Async Functions
export const getRMA = createAsyncThunk('rma/getRMA', async (arg) => {
  const res = await axios
    .get(`${env.API_URL}/rmas/${arg}?populate=debitor,articles`, {
      headers: {
        jwt: localStorage.getItem('microsoftJWT'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data.data.data)
    .catch((err) => {
      recallLoginSSO();
      return err.response;
    });
  return res;
});

export const putRMA = createAsyncThunk('rma/putRMA', async (arg) => {
  const { rmaID, updateData } = arg;
  const username = localStorage.getItem('username');
  const res = await axios
    .put(
      `${env.API_URL_PREFIX}/update-rma/${rmaID}`,
      { ...updateData, username },
      {
        headers: {
          jwt: localStorage.getItem('microsoftJWT'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((data) => data.data.data)
    .catch((err) => err.response);
  return res;
});

export const putARTICLE = createAsyncThunk('rma/putARTICLE', async (arg) => {
  const { articleID, updateData } = arg;
  const res = await axios
    .put(`${env.API_URL_PREFIX}/update-article/${articleID}`, updateData, {
      headers: {
        jwt: localStorage.getItem('microsoftJWT'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data.data.data)
    .catch((err) => err.response);
  return res;
});
