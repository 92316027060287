import { createSlice } from '@reduxjs/toolkit';
import {
  getDashboardAccepted,
  getDashboardArrived,
  getDashboardTesting,
} from './asyncDashboard';

const initialState = {
  isLoading: true,
  isError: null,
  reload: 0,
  dataAccepted: {},
  dataArrived: {},
  dataTesting: {},
};

export const DASHBOARD_SLICE = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardAccepted.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(getDashboardAccepted.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.dataAccepted = payload;
      })
      .addCase(getDashboardAccepted.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getDashboardArrived.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(getDashboardArrived.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.dataArrived = payload;
      })
      .addCase(getDashboardArrived.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getDashboardTesting.pending, (state) => {
        state.isLoading = true;
        state.isError = null;
      })
      .addCase(getDashboardTesting.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.dataTesting = payload;
      })
      .addCase(getDashboardTesting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = DASHBOARD_SLICE.actions;

export default DASHBOARD_SLICE.reducer;
