export function checkArrayForText(arr, text) {
  // Check if the text exists in the array
  if (arr.includes(text)) {
    return false;
  }
  return true;
}

export function realStatus(value) {
  switch (value) {
    case 'new':
      return 'open';
    case 'verify':
      return 'pending';
    case 'accepted':
      return 'accepted';
    case 'shipment':
      return 'shipped';
    case 'error':
      return 'testing';
    case 'done':
      return 'verified';
    case 'close':
      return 'close';
    default:
      break;
  }
}


export function removeEmptyKeys(obj) {
  for (const key in obj) {
    if (Array.isArray(obj[key]) || typeof obj[key] === 'string') {
      if (obj[key].length === 0) {
        delete obj[key];
      }
    }
  }
  return obj;
}


export function normalizeValue(value) {
  let result = value
    .replace(/([0-9])/g, ' $1')  // Add space before numbers
    .replace(/([A-Z])/g, ' $1')  // Add space before uppercase letters
    .replace(/_/g, ' ')          // Replace underscores with spaces
    .trim()                      // Remove any leading/trailing spaces
    .toLowerCase();              // Convert to lower case

  // Capitalize the first letter of each word
  result = result.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });

  // Capitalize the first character of the entire string
  result = result.charAt(0).toUpperCase() + result.slice(1);

  return result;
}


export const checkClassification = (gir) => {
  const bronzeShadow = 'rgba(205, 127, 50, 0.4)';
  const silverShadow = 'rgba(192, 192, 192, 0.9)';
  const goldShadow = 'rgba(255, 215, 0)';
  switch (gir) {
    case 'gold':
      return goldShadow;
    case 'silver':
      return silverShadow;
    default:
      return bronzeShadow;
  }
};

export const loopArticleReturnStatus = (arr) => {
  if (Array.isArray(arr)) {
    var showButton = "wait";
    var counterAllowed = 0
    var counterRej = 0
    var arrLength = arr.length

    for (const item of arr) {
      if (item?.attributes?.guarantee === "pending") {
        showButton = "wait"
        break;
      } else {
        if (item?.attributes?.guarantee !== "allowed") {
          counterRej++
          showButton = "next"
        } else {
          counterAllowed++
          showButton = "next"
        }
      }
    }

    if (showButton !== "wait") {
      if (counterRej === arrLength) {
        return "reject"
      } else {
        return "accept"
      }
    } else {
      return showButton
    }

  }
}


export const errorCodeCheckDone = (arr) => {
  var counterAllowed = 0
  var errorCodeDone = 0
  var counterRej = 0

  for (const item of arr) {
    if (item?.attributes?.guarantee === "allowed") {
      counterAllowed++
      if (item?.attributes?.errorCode !== null) {
        errorCodeDone++
      }
    } else {
      counterRej++
    }
  }

  if (counterAllowed == errorCodeDone) {
    return true
  } else {
    return false
  }

}