import React from 'react';
import GraphCard from 'src/components/GraphCard';
import { Stack, Paper, Typography, Divider, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions';
import Loading from 'src/components/Loading';
import DashboardRmaRow from 'src/components/DashboardRmaRow';

function DashboardPage() {
  const dispatch = useDispatch();
  const { dataAccepted, dataArrived, dataTesting, isLoading, isError } = useSelector(
    (state) => state.dashboard
  );
  const [rmaSetter, setRmaSetter] = React.useState({ open: false, title: '', data: [] });

  React.useEffect(() => {
    dispatch(actions.DASHBOARD.getDashboardArrived());
    dispatch(actions.DASHBOARD.getDashboardAccepted());
    dispatch(actions.DASHBOARD.getDashboardTesting());
  }, []);

  if (isLoading) return <Loading color="success" />;
  if (isError) return <>{'error'}</>;

  return (
    <Paper sx={{ p: 2 }}>
      <Stack sx={{ gap: '12px' }}>
        <Box>
          <Typography variant="subtitle2" sx={{ fontSize: 16 }}>
            Dashboard
          </Typography>
          <Divider sx={{ mt: 0.5, mb: 3 }} />
        </Box>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: '12px' }}>
          <GraphCard
            title="ARTICLES INCOMING"
            number={dataAccepted.articlesLengthAllRmas}
            onClick={() =>
              setRmaSetter({
                open: true,
                title: 'ARTICLES INCOMING',
                data: dataAccepted.resultsArticles,
              })
            }
          />
          <GraphCard
            title="ARTICLES ARRIVED"
            number={dataArrived.articlesLengthAllRmas}
            onClick={() =>
              setRmaSetter({
                open: true,
                title: 'ARTICLES ARRIVED',
                data: dataArrived.resultsArticles,
              })
            }
          />
          <GraphCard
            title="ARTICLES TESTING"
            number={dataTesting.articlesLengthAllRmas}
            onClick={() =>
              setRmaSetter({
                open: true,
                title: 'ARTICLES TESTING',
                data: dataTesting.resultsArticles,
              })
            }
          />
        </Stack>

        {rmaSetter?.open && (
          <DashboardRmaRow title={rmaSetter.title} data={rmaSetter.data} />
        )}
      </Stack>
    </Paper>
  );
}

export default DashboardPage;
