import React from 'react';
import { Box, Button, Divider, IconButton, Tooltip, Menu, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions';
import { guaranteeColor, guaranteeIcon } from 'src/utils/guaranteeUtils';

export default function GuaranteeBtnGroup({ id }) {
  const dispatch = useDispatch();
  const rmaID = useSelector((state) => state.rma.data.id);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (
    guarantee,
    windowMessage,
    successSnackbarMessage,
    callbackSuccess
  ) => {
    if (window.confirm(windowMessage)) {
      dispatch(
        actions.ARTICLE.putARTICLE({
          articleID: id,
          updateData: {
            guarantee: guarantee,
          },
        })
      )
        .unwrap()
        .then((res) => {
          callbackSuccess && callbackSuccess();
          enqueueSnackbar(
            `${guarantee !== 'rejected' ? '' : '❌ '}${successSnackbarMessage}`,
            {
              variant: 'success',
            }
          );
        });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 2,
      }}
      flexGrow={1}
      flexShrink={1}
      flexBasis="auto"
    >
      <Tooltip title="Accept Guarantee">
        <Button
          size="small"
          sx={{
            bgcolor: guaranteeColor('allowed'),
            color: 'black',
            ':hover': {
              bgcolor: '#229A16',
              color: 'black',
            },
          }}
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            handleClick(
              'allowed',
              'Sure you will Accept Guarantee?',
              'Guarantee Accepted',
              () =>
                dispatch(
                  actions.RMA.putRMA({
                    rmaID: rmaID,
                    updateData: { status: 'pending' },
                  })
                )
            );
          }}
        >
          {guaranteeIcon('allowed')}
        </Button>
      </Tooltip>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        color="error"
        size="small"
        variant="contained"
      >
        {guaranteeIcon('rejected')}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClick(
              'wrong-customer',
              'Sure you will Reject Guarantee?',
              'Guarantee Rejected',
              () => {
                handleClose();
                dispatch(
                  actions.RMA.putRMA({
                    rmaID: rmaID,
                    updateData: { status: 'pending' },
                  })
                );
              }
            );
          }}
        >
          Wrong Customer
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClick(
              'out-of-warranty',
              'Sure you will Reject Guarantee?',
              'Guarantee Rejected',
              () => {
                handleClose();
                dispatch(
                  actions.RMA.putRMA({
                    rmaID: rmaID,
                    updateData: { status: 'pending' },
                  })
                );
              }
            );
          }}
        >
          Out-Of-Warranty
        </MenuItem>
      </Menu>
    </Box>
  );
}
