import React from 'react';
import { Paper, Typography, Stack, Divider } from '@mui/material';

function GraphCard({ title, number, onClick }) {
  return (
    <Paper
      onClick={onClick}
      elevation={5}
      sx={{
        p: 2,
        cursor: 'pointer',
        '&:hover': {
          borderBottom: 3,
          borderColor: 'grey', // or 'red' if you don't want to use theme
        },
      }}
    >
      <Stack>
        <Typography>{title}</Typography>
        <Divider />
        <Typography sx={{ textAlign: 'center' }}>{number}</Typography>
      </Stack>
    </Paper>
  );
}

export default GraphCard;
