import React from 'react';
import af from '../assets/flags/af.svg';
import al from '../assets/flags/al.svg';
import am from '../assets/flags/am.svg';
import be from '../assets/flags/be.svg';
import de from '../assets/flags/de.svg';
import dk from '../assets/flags/dk.svg';
import es from '../assets/flags/es.svg';
import fr from '../assets/flags/fr.svg';
import hu from '../assets/flags/hu.svg';
import it from '../assets/flags/it.svg';
import md from '../assets/flags/md.svg';
import pl from '../assets/flags/pl.svg';
import pt from '../assets/flags/pt.svg';
import rs from '../assets/flags/rs.svg';
import se from '../assets/flags/se.svg';
import xk from '../assets/flags/xk.svg';
import gr from '../assets/flags/gr.svg';
import il from '../assets/flags/il.svg';
import az from '../assets/flags/az.svg';
import ro from '../assets/flags/ro.svg';

function Flags({ flag }) {
  switch (String(flag).toLowerCase()) {
    case 'afghanistan':
      return <img alt="af" src={af} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'albania':
      return <img alt="al" src={al} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'armenia':
      return <img alt="am" src={am} style={{ width: '15px', height: '15px',borderRadius:"12px" }}  />;
    case 'belgium':
      return <img alt="be" src={be} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'germany':
      return <img alt="be" src={de} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'denmark':
      return <img alt="dk" src={dk} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'spain':
      return <img alt="es" src={es} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'france':
      return <img alt="fr" src={fr} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'hungary':
      return <img alt="hu" src={hu} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'italy':
      return <img alt="it" src={it} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'moldova':
      return <img alt="md" src={md} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'poland':
      return <img alt="pl" src={pl} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'portugal':
      return <img alt="pt" src={pt} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'serbia':
      return <img alt="rs" src={rs} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'sweden':
      return <img alt="se" src={se} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'kosovo':
      return <img alt="xk" src={xk} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'greece':
      return <img alt="gr" src={gr} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'israel':
      return <img alt="il" src={il} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'azerbaijan':
      return <img alt="az" src={az} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    case 'romania':
      return <img alt="ro" src={ro} style={{ width: '15px', height: '15px',borderRadius:"12px" }} />;
    default:
      return flag;
  }
}

export default Flags;
