import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import env from 'src/env';
import actions from '../actions';

export const postShipment = createAsyncThunk(
  'shipment_modal/postShipment',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const ticketId = state.rma.data.id;
    const { articles, shipment, shipment_details, deliverynote } = state.shipment_modal;
    const res = await axios
      .post(
        `${env.REACT_APP_SSO_BACKEND}/shipment/`,
        {
          provider: 'dhl',
          user_action: 'create',
          ticketId: ticketId,
          data: {
            shipment: {
              ...shipment,
              shipment_details,
            },
            deliverynote: {
              rmaNumber: deliverynote.rmaNumber,
              shipping: {
                name: shipment.name1,
                address: `${shipment.streetName} ${shipment.streetNumber}`,
                city: `${shipment.zip} ${shipment.city}`,
                state: `${shipment.countryISOCODE}`,
                country: `${shipment.countryISOCODE}`,
              },
              items: articles,
            },
          },
        },
        {
          headers: {
            jwt: localStorage.getItem('microsoftJWT'),
          },
        }
      )
      .then((res) => {
        const { status, data } = res;
        if (status === 200) {
          thunkAPI.dispatch(
            actions.RMA.putRMA({
              rmaID: ticketId,
              updateData: { status: 'close' },
            })
          );
        }
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });

    return res;
  }
);
