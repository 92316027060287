import React, { useState, useCallback } from 'react';
import AddArticles from './AddArticles';
import CustomerAdress from './CustomerAdress';
import ReseumeeShipment from './ReseumeeShipment';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  IconButton,
  StepContent,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import actions from 'src/store/actions';
import PackageInfo from './PackageInfo';

const steps = ['Add Articles', 'Customer Info', 'Package Info', 'Reseumee'];

function ShipmentModal() {
  const dispatch = useDispatch();
  const { open, activeStep } = useSelector((state) => state.shipment_modal);

  React.useEffect(() => {
    dispatch(actions.SHIPMENT_MODAL.nextStep(0));
    dispatch(actions.SHIPMENT_MODAL.resetItems());
    dispatch(actions.SHIPMENT_MODAL.resetShipment());
  }, []);

  const handleClose = () => {
    if (window.confirm('You are sure, close this Window?')) {
      dispatch(actions.SHIPMENT_MODAL.openModal());
      dispatch(actions.SHIPMENT_MODAL.nextStep(0));
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AddArticles />;
      case 1:
        return <CustomerAdress />;
      case 2:
        return <PackageInfo />;
      case 3:
        return <ReseumeeShipment />;
      default:
        return 'Unknown step';
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Shipment Dialog</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ width: 600 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>{getStepContent(activeStep)}</StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
    </Dialog>
  );
}

export default ShipmentModal;
