import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';

// store
import { store } from './store';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeProvider>
          <SnackbarProvider maxSnack={4}>
            <Router />
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  );
}
