import React from 'react';
import env from 'src/env';

function LogoutPage() {
  React.useEffect(() => {
    window.location.href = env.REACT_APP_SSO_BACKEND + '/auth/sso/logout';
  }, []);

  return <div>LogoutPage</div>;
}

export default LogoutPage;
