import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions';
import BasicTable from 'src/components/Table';
import Loading from 'src/components/Loading';
import FilterButtons from 'src/components/FilterButtons';
import ArticleTable from 'src/components/ArticleTable';

function RmasPage() {
  const dispatch = useDispatch();
  const { isLoading, data, isError, articlesSearch } = useSelector((state) => state.rmas);

  const columns = [
    { name: 'Number' },
    { name: 'Created' },
    { name: 'Status' },
    { name: 'External No' },
  ];

  React.useEffect(() => {
    dispatch(actions.RMA.getAllRmas());
  }, []);

  if (isLoading) return <Loading color="success" />;
  if (isError) return <div>isError</div>;

  return (
    <>
      <FilterButtons />
      {articlesSearch.length > 0 ? (
        <ArticleTable />
      ) : (
        <BasicTable columns={columns} rows={data} navigatePath={'/rma/'} />
      )}
    </>
  );
}

export default RmasPage;
