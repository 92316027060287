import { Chip, Stack, Paper, Typography, Divider, Box } from '@mui/material';
import React from 'react';

function ConversationRow({ statusText, borderColor, infoText, username, createdAt }) {
  return (
    <Box>
      <Paper
        elevation={4}
        sx={{ minHeight: '4em', p: 1, borderLeft: 3, borderColor: { borderColor } }}
      >
        <Stack sx={{ alignItems: 'center' }} direction={'row'}>
          <Typography variant="subtitle2">Status:</Typography>
          <Chip
            color="success"
            sx={{ ml: 1, borderRadius: 1 }}
            size="small"
            label={statusText}
          />
        </Stack>
        <Stack>
          <Typography sx={{ mt: 1, overflowWrap: 'break-word' }} variant="body1">
            {infoText}
          </Typography>
          {/* <Divider /> */}
        </Stack>
      </Paper>
      <Stack
        sx={{ my: 1 }}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'flex-end'}
      >
        <Typography variant="subtitle2">{createdAt}</Typography>
        <Chip
          color="info"
          sx={{ ml: 1, borderRadius: 1 }}
          size="small"
          label={username.toUpperCase()}
        />
      </Stack>
    </Box>
  );
}

export default ConversationRow;
