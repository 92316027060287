import env from '../../env';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { recallLoginSSO } from 'src/utils/loginUtils';

export const getCustomer = createAsyncThunk('customer/getCustomer', async (arg) => {
  const res = await axios
    .get(`${env.API_URL}/debitors/${arg}`, {
      headers: {
        jwt: localStorage.getItem('microsoftJWT'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data.data)
    .catch((err) => {
      recallLoginSSO();
      return err.response;
    });
  return res;
});
