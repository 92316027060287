import React, { useState } from 'react';
import { guaranteeColor, guaranteeIcon } from 'src/utils/guaranteeUtils';
import {
  Divider,
  Button,
  Box,
  TextField,
  Typography,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Stack,
  Menu,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions';
import { removeEmptyKeys } from 'src/utils/rmasUtils';
import NewArticleBox from './NewArticleBox';
import BenchmarkList from './BenchmarkList';

const handleClick = (
  dispatchAction,
  snackbar,
  windowMessage,
  successSnackbarMessage,
  callbackDone,
  callbackError
) => {
  if (window.confirm(windowMessage)) {
    dispatchAction()
      .unwrap()
      .then((res) => {
        snackbar(`${successSnackbarMessage}`, {
          variant: 'success',
        });
        if (callbackDone) callbackDone();
      });
  }
};

const ChangeArticle = ({ id, guarantee, newSerial, newHsn, newSku }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    newSerial: '',
    newHsn: '',
    newSku: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let tempFormData = removeEmptyKeys(formData);
    handleClick(
      () =>
        dispatch(
          actions.ARTICLE.putARTICLE({
            articleID: id,
            updateData: tempFormData,
          })
        ),
      enqueueSnackbar,
      'Austausch Speichern?',
      'Sie haben das Austausch Erfüllt!'
    );
  };

  if (newSerial && newSerial.length > 0) {
    return (
      <NewArticleBox
        title={'2.Swap Article Information'}
        obj={{ newSerial, newHsn, newSku }}
      />
    );
  }

  return (
    <form
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '12px',
        flexGrow: 3,
        flexShrink: 1,
        flexBasis: 'auto',
      }}
      onSubmit={handleSubmit}
    >
      <Box sx={{ width: '100%' }}>
        <Typography variant="subtitle2">2. Austausch Artikel</Typography>
        <Divider />
      </Box>
      <TextField
        name="newSerial"
        value={formData.newSerial}
        onChange={handleChange}
        label="New Serial"
        variant="outlined"
        fullWidth
      />
      <TextField
        name="newHsn"
        value={formData.newHsn}
        onChange={handleChange}
        label="New HSN"
        variant="outlined"
        fullWidth
      />
      <TextField
        name="newSku"
        value={formData.newSku}
        onChange={handleChange}
        label="New SKU Number"
        variant="outlined"
        fullWidth
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 2,
        }}
        flexGrow={5}
        flexShrink={1}
        flexBasis="auto"
      >
        <Button
          startIcon={guaranteeIcon('allowed')}
          type="submit"
          sx={{
            bgcolor: guaranteeColor('allowed'),
            color: 'black',
            ':hover': {
              bgcolor: '#229A16',
              color: 'black',
            },
          }}
          variant="contained"
        >
          Austausch Speichern
        </Button>
      </Box>
    </form>
  );
};

const DiagnoseForm = ({ id, errorCode, errorDescription }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    errorCode: '',
    errorDescription: '',
    paletteNo: '',
    boxNo: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.currentTarget;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeSelect = (event) => {
    console.log(event);
    const value = event.target.value;
    setFormData({
      ...formData,
      errorCode: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let tempFormData = removeEmptyKeys(formData);
    console.log(tempFormData, formData);

    handleClick(
      () =>
        dispatch(
          actions.ARTICLE.putARTICLE({
            articleID: id,
            updateData: tempFormData,
          })
        ),
      enqueueSnackbar,
      'Diagnose Speichern?',
      'Sie haben die Diagnose Erfüllt!',
      setFormData({
        errorCode: '',
        errorDescription: '',
      })
    );
  };

  if (errorCode && errorCode.length > 0) {
    return (
      <NewArticleBox
        title={'1.Error Information'}
        obj={{ errorCode, errorDescription }}
      />
    );
  }

  return (
    <form
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '12px',
        flexGrow: 5,
        flexShrink: 4,
        flexBasis: 'auto',
      }}
      onSubmit={handleSubmit}
    >
      <Box sx={{ width: '100%' }}>
        <Typography variant="subtitle2">1. Diagnose</Typography>
        <Divider />
      </Box>
      <FormControl fullWidth size="small">
        <InputLabel id="errorCode-select-label">Error Code</InputLabel>
        <Select
          labelId="errorCode-select-label"
          id="errorCode-simple-select"
          value={formData.errorCode}
          label="Error Code"
          onChange={handleChangeSelect}
          size="small"
        >
          <MenuItem value={'test_ok'}>Test OK</MenuItem>
          <MenuItem value={'faulty'}>Faulty</MenuItem>
          <MenuItem value={'cid'}>CID</MenuItem>
          <MenuItem value={'not_arrived'}>Not Included</MenuItem>
        </Select>
      </FormControl>

      {formData.errorCode === 'faulty' && (
        <Stack flexDirection="row" sx={{ width: '100%', gap: '12px' }}>
          <TextField
            sx={{ width: '100%' }}
            onChange={handleChange}
            name="paletteNo"
            value={formData.paletteNo}
            label="Palette No"
            variant="outlined"
          />
          <TextField
            fullwidth
            sx={{ width: '100%' }}
            onChange={handleChange}
            name="boxNo"
            value={formData.boxNo}
            label="Box No"
            variant="outlined"
          />
        </Stack>
      )}
      <TextField
        multiline
        minRows={3}
        onChange={handleChange}
        name="errorDescription"
        value={formData.errorDescription}
        label="Error Description"
        variant="outlined"
        fullWidth
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 2,
        }}
        flexGrow={5}
        flexShrink={1}
        flexBasis="auto"
      >
        {formData.errorCode && formData.errorDescription && (
          <Button
            type="submit"
            startIcon={guaranteeIcon('allowed')}
            sx={{
              bgcolor: guaranteeColor('allowed'),
              color: 'black',
              ':hover': {
                bgcolor: '#229A16',
                color: 'black',
              },
            }}
            variant="contained"
          >
            Diagnose Speichern
          </Button>
        )}
      </Box>
    </form>
  );
};

const AcceptGuarantee = ({ id, guarantee }) => {
  const dispatch = useDispatch();
  const rmaID = useSelector((state) => state.rma.data.id);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    guarantee,
    windowMessage,
    successSnackbarMessage,
    callbackSuccess
  ) => {
    if (window.confirm(windowMessage)) {
      dispatch(
        actions.ARTICLE.putARTICLE({
          articleID: id,
          updateData: {
            guarantee: guarantee,
          },
        })
      )
        .unwrap()
        .then((res) => {
          callbackSuccess && callbackSuccess();
          enqueueSnackbar(`${successSnackbarMessage}`, {
            variant: 'success',
          });
        });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography variant="subtitle2">Artikel Guarantee</Typography>
        <Divider />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
        flexGrow={5}
        flexShrink={1}
        flexBasis="auto"
      >
        <Button
          onClick={() => {
            handleClick(
              'allowed',
              'Sure you will Accept Guarantee?',
              'Guarantee Accepted',
              () =>
                dispatch(
                  actions.RMA.putRMA({
                    rmaID: rmaID,
                    updateData: { status: 'pending' },
                  })
                )
            );
          }}
          startIcon={guaranteeIcon('allowed')}
          sx={{
            bgcolor: guaranteeColor('allowed'),
            color: 'black',
            ':hover': {
              bgcolor: '#229A16',
              color: 'black',
            },
          }}
          variant="contained"
        >
          Accept
        </Button>

        <Button
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          startIcon={guaranteeIcon('rejected')}
          color="error"
          variant="contained"
        >
          Reject
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              handleClick(
                'wrong-customer',
                'Sure you will Reject Guarantee?',
                'Guarantee Rejected',
                () => {
                  handleClose();
                  dispatch(
                    actions.RMA.putRMA({
                      rmaID: rmaID,
                      updateData: { status: 'pending' },
                    })
                  );
                }
              );
            }}
          >
            Wrong Customer
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClick(
                'out-of-warranty',
                'Sure you will Reject Guarantee?',
                'Guarantee Rejected',
                () => {
                  handleClose();
                  dispatch(
                    actions.RMA.putRMA({
                      rmaID: rmaID,
                      updateData: { status: 'pending' },
                    })
                  );
                }
              );
            }}
          >
            Out-Of-Warranty
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default function ArticleForm({
  id,
  guarantee,
  newSerial,
  newSku,
  newHsn,
  errorCode,
  errorDescription,
  furmark,
  heaven4,
  mark3d,
}) {
  return (
    <Box
      sx={{
        p: 2,
        borderLeft: 3,
        borderColor: '#229A16',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      gap={2}
    >
      {guarantee === 'pending' && <AcceptGuarantee id={id} guarantee={guarantee} />}
      {guarantee === 'allowed' && (
        <DiagnoseForm errorCode={errorCode} errorDescription={errorDescription} id={id} />
      )}
      {guarantee === 'allowed' && (
        <BenchmarkList id={id} furmark={furmark} heaven4={heaven4} mark3d={mark3d} />
      )}
      {guarantee === 'allowed' && (
        <ChangeArticle newSku={newSku} newSerial={newSerial} newHsn={newHsn} id={id} />
      )}
    </Box>
  );
}
