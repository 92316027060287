import env from '../../env';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getArticle = createAsyncThunk('article/getArticle', async (arg) => {
  const res = await axios
    .get(`${env.API_URL}/warehouses/${arg}?populate=rma`, {
      headers: {
        jwt: localStorage.getItem('microsoftJWT'),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((data) => data.data)
    .catch((err) => err.response);
  return res;
});

export const postXLSX = createAsyncThunk(
  'article/postXLSX',
  async (arg, { getState }) => {
    const state = getState();
    const articlesXLSX = state.article.xlsxArticles;
    const customerID = state.customer.data.id;
    const customerCode = state.customer.data.attributes.customerCode;

    const res = await axios
      .post(
        `${env.API_URL_PREFIX}/service-xlsx/${customerID}/${customerCode}/${arg}`,
        articlesXLSX,
        {
          headers: {
            jwt: localStorage.getItem('microsoftJWT'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((data) => {
        console.log(data.data);
        return data.data;
      })
      .catch((err) => err.response);
    return res;
  }
);
