import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import StatusTicket from './StatusTicket';
import { useSelector } from 'react-redux';

export default function ArticleTable() {
  const { isLoading, isError, articlesSearch } = useSelector((state) => state.rmas);

  const navigate = useNavigate();
  const columns = [
    { name: 'Serial' },
    { name: 'HSN' },
    { name: 'Type' },
    { name: 'Rma Number' },
    { name: 'Guarantee' },
  ];
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              const { name, align } = column;
              return <TableCell align={align ? align : 'left'}>{name}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {articlesSearch.map((row) => {
            const { attributes, id } = row;
            const { rma, serial, type, hsn, guarantee } = attributes;
            const debitorId = rma.data.attributes.debitor.data.id;
            const rmaId = rma.data.id;
            const rmaNumber = rma.data.attributes.number;
            const url = `/customer/${debitorId}/rma/${rmaId}`;
            return (
              <TableRow
                onClick={() => navigate(url)}
                hover
                key={serial}
                sx={{
                  ':hover': {
                    cursor: 'pointer',
                  },
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell>{serial}</TableCell>
                <TableCell>{hsn}</TableCell>
                <TableCell>{type}</TableCell>
                <TableCell>{rmaNumber}</TableCell>
                <TableCell>{guarantee}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
