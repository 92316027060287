import { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import React from 'react';
import Header from './header';
import Nav from './nav';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions';
//

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function ZotacLayout() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, isError, data } = useSelector((state) => state.account);

  // React.useEffect(() => {
  //   dispatch(actions.ACCOUNT.ssoLogin());
  // }, []);

  if (!isError) {
    if (localStorage.getItem('microsoftJWT')) {
      return (
        <StyledRoot>
          <Header onOpenNav={() => setOpen(true)} />
          <Nav openNav={open} onCloseNav={() => setOpen(false)} />
          <Main>
            <Outlet />
          </Main>
        </StyledRoot>
      );
    }
  }

  return <Navigate to={'/auth/login'} />;
}
