import { Box, IconButton, Stack, Paper } from '@mui/material';
import React from 'react';
import './FilterButtons.css';
import { useDispatch } from 'react-redux';
import ReactCurvedText from 'react-curved-text';
// import { getTicketsKingstonV2 } from './ticketsSlice';
//
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import actions from 'src/store/actions';

const filterButtons = [
  {
    fullName: 'Open',
    icon: <FiberNewOutlinedIcon />,
    // addAction: () => console.log('hello'),
    deleteAction: () => console.log('Bello'),
    activated: true,
    status: 'open',
  },
  {
    fullName: 'Verify G',
    icon: <PendingActionsOutlinedIcon />,
    // addAction: () => console.log('hello'),
    deleteAction: () => console.log('Bello'),
    activated: false,
    status: 'pending',
  },
  {
    fullName: 'Accepted',
    icon: <CheckCircleOutlinedIcon />,
    // addAction: () => console.log('hello'),
    deleteAction: () => console.log('Bello'),
    activated: false,
    status: 'accepted',
  },
  {
    fullName: 'Arrived',
    icon: <LocalShippingOutlinedIcon />,
    // addAction: () => console.log('hello'),
    deleteAction: () => console.log('Bello'),
    activated: false,
    status: 'shipped',
  },
  {
    fullName: 'Testing',
    icon: <BugReportOutlinedIcon />,
    // addAction: () => console.log('hello'),
    deleteAction: () => console.log('Bello'),
    activated: false,
    status: 'testing',
  },
  {
    fullName: 'Check Done',
    icon: <VerifiedUserOutlinedIcon />,
    // addAction: () => console.log('hello'),
    deleteAction: () => console.log('Bello'),
    activated: false,
    status: 'verified',
  },
  {
    fullName: 'Closed',
    icon: <LockOutlinedIcon />,
    // addAction: () => console.log('hello'),
    deleteAction: () => console.log('Bello'),
    activated: false,
    status: 'close',
  },
];

function FilterButtons() {
  const dispatch = useDispatch();
  const [filterUsed, setFilter] = React.useState('');

  const handleClick = (event, addAction, deleteAction, index, callback, status) => {
    if (!event.currentTarget.classList.contains('active-test')) {
      event.currentTarget.classList.toggle('active-test');
      filterButtons[index]['activated'] = true;
      addAction && dispatch(addAction());
      dispatch(actions.RMA.getFilteredRmas({ criteria: 'status', searchValue: status }))
        .unwrap()
        .then((res) => {
          callback && callback(res);
          setFilter(status);
          return res;
        })
        .catch((err) => console.log(err));
    } else {
      event.currentTarget.classList.remove('active-test');
      filterButtons[index]['activated'] = false;
      addAction && dispatch(deleteAction());
      dispatch(actions.RMA.getFilteredRmas())
        .unwrap()
        .then((res) => {
          setFilter('');
          callback && callback(res);
          return res;
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Paper
      sx={{
        borderRadius: '8px',
        paddingX: 8,
        p: 3,
      }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap:'wrap',
          gap: 6,
        }}
      >
        {filterButtons.map((item, index) => {
          let activated = item['activated'];
          let status = item['status'];
          const idEl = item['name'] + index;
          return (
            <div style={{ position: 'relative', height: '77px' }}>
              <div style={{ position: 'absolute' }}>
                <ReactCurvedText
                  width={80}
                  height={80}
                  cx={40.5}
                  cy={36.5}
                  rx={38.5}
                  ry={39.5}
                  startOffset={69}
                  text={item['fullName'].toUpperCase()}
                  textProps={{ fontSize: '13px' }}
                />
              </div>
              <IconButton sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Box
                  sx={{
                    border: '3px solid',
                    borderColor: 'linear-gradient(to left, red 50%, blue 50%) right',
                    width: '55px',
                    height: '55px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '50%',
                    boxShadow: '0px 13px 14px 0px rgba(0,0,0,0.25)',
                  }}
                >
                  <Box
                    id={idEl}
                    onClick={(e) =>
                      handleClick(
                        e,
                        item['addAction'],
                        item['deleteAction'],
                        index,
                        undefined,
                        status
                      )
                    }
                    className={`${filterUsed === status && 'active-test'}`}
                    sx={{
                      display: 'flex',
                      width: '45px',
                      height: '45px',
                      borderRadius: '50%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    }}
                  >
                    <span style={{ fontSize: '10px' }}>
                      {item['name'] ? item['name'] : item['icon']}
                    </span>
                  </Box>
                </Box>
              </IconButton>
            </div>
          );
        })}
      </Stack>
    </Paper>
  );
}

export default FilterButtons;
