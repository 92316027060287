import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomerCard from 'src/components/CustomerCard/CustomerCard';
import actions from 'src/store/actions';
import Grid from '@mui/material/Unstable_Grid2';
import AlphabetBar from 'src/components/AlphabetBar';
import { Stack } from '@mui/material';
import Loading from 'src/components/Loading';

// const sorti = (unsortedArray, sortedIDs) => {
//   // Create an array to hold the final sorted elements
//   let sortedArray = [];

//   // Create an array to hold elements that are not in the sortedIDs
//   let remainingElements = [];

//   // Loop through the unsortedArray
//   for (const obj of unsortedArray) {
//     const index = sortedIDs.indexOf(obj.attributes.classification);
//     if (index !== -1) {
//       // If the ID exists in the sorted array, place the object at the corresponding position
//       sortedArray[index] = obj;
//     } else {
//       // Otherwise, store it for later
//       remainingElements.push(obj);
//     }
//   }

//   // Append the remaining elements to the sorted array
//   return (sortedArray = [...sortedArray, ...remainingElements]);
// };

const sorti = (unsortedArray, sortedIDs) => {
  // Create an array to hold the final sorted elements
  let sortedArray = [];

  // Create an array to hold elements that are not in the sortedIDs
  let remainingElements = [];

  // Create a map to hold elements that should be sorted
  let sortMap = new Map();

  // Loop through the unsortedArray
  for (const obj of unsortedArray) {
    const index = sortedIDs.indexOf(obj.attributes.classification);
    if (index !== -1) {
      // If the ID exists in the sorted array, place the object at the corresponding position
      if (!sortMap.has(index)) {
        sortMap.set(index, []);
      }
      sortMap.get(index).push(obj);
    } else {
      // Otherwise, store it for later
      remainingElements.push(obj);
    }
  }

  // Build the sorted array
  for (const id of sortedIDs) {
    const index = sortedIDs.indexOf(id);
    if (sortMap.has(index)) {
      sortedArray.push(...sortMap.get(index));
    }
  }

  // Append the remaining elements to the sorted array
  return [...sortedArray, ...remainingElements];
};


function CustomersPage() {
  const dispatch = useDispatch();
  const [sortedArray, setSortedArray] = React.useState([
    'gold',
    'silver',
    'bronze',
    'null',
    'undefined',
  ]);

  const { isLoading, data, isError } = useSelector((state) => state.customers);

  React.useEffect(() => {
    dispatch(actions.CUSTOMERS.getAllCustomers());
  }, [dispatch]);

  if (isLoading) return <Loading color="success" />;
  if (isError) return <div>Error</div>;

  if (data.length === 0) return <div> NO RESULT</div>;

  return (
    <Grid container alignItems={'center'} spacing={1}>
      {sorti(data, sortedArray).map((customer) => {
        const { attributes, id } = customer;
        const { debitor } = attributes;

        return (
          <Grid
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={'100%'}
            xs={12}
            // sm={6}
            // md={4}
          >
            <CustomerCard attributes={attributes} id={id} debitor={debitor} />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default CustomersPage;
