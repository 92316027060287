import env from '../../env';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { recallLoginSSO } from 'src/utils/loginUtils';

//Async Functions
export const getAllRmas = createAsyncThunk('rmas/getAllRmas', async (arg) => {
  arg = arg === undefined ? { criteria: 'number', searchValue: '' } : arg;
  let { criteria, searchValue } = arg;
  searchValue = searchValue === undefined ? '' : searchValue;
  criteria = criteria === '' ? 'number' : criteria;

  const res = await axios
    .get(
      `${env.API_URL}/rmas?filters[${criteria}][$contains]=${searchValue}&sort=createdAt:desc&pagination[limit]=-1&populate=*`,
      {
        headers: {
          jwt: localStorage.getItem('microsoftJWT'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((data) => data.data)
    .catch((err) => {
      recallLoginSSO();
      return err.response;
    });
  return res;
});

export const getFilteredRmas = createAsyncThunk('rmas/getFilteredRmas', async (arg) => {
  arg = arg === undefined ? { criteria: 'number', searchValue: '' } : arg;
  let { criteria, searchValue } = arg;
  searchValue = searchValue === undefined ? '' : searchValue;
  const res = await axios
    .get(
      `${env.API_URL}/rmas?filters[${criteria}][$contains]=${searchValue}&sort=createdAt:desc&pagination[limit]=-1&populate=*`,
      {
        headers: {
          jwt: localStorage.getItem('microsoftJWT'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((data) => data.data)
    .catch((err) => {
      recallLoginSSO();
      return err.response;
    });
  return res;
});

export const getFilteredArticle = createAsyncThunk(
  'rmas/getFilteredArticle',
  async (arg) => {
    arg = arg === undefined ? '' : arg;
    const res = await axios
      .get(
        `${env.API_URL}/articles?filters[serial][$contains]=${arg}&populate[rma][populate]=debitor&pagination[limit]=-1`,
        {
          headers: {
            jwt: localStorage.getItem('microsoftJWT'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((data) => data.data)
      .catch((err) => {
        recallLoginSSO();
        return err.response;
      });
    return res;
  }
);
