import React from 'react';
import { Box, ButtonGroup, Button, Typography, Stack, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions';
import Loading from '../Loading';

function ReseumeeShipment() {
  const dispatch = useDispatch();
  const { isLoading, isError, successRequest } = useSelector(
    (state) => state.shipment_modal
  );
  if (isLoading) return <Loading color={'success'} />;
  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography>Tracking Nr:</Typography>
          <Typography>{successRequest?.trackingNumber}</Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Stack
          sx={{ gap: 2 }}
          justifyContent={'center'}
          flexDirection={'row'}
          flexWrap={'wrap'}
        >
          <Box>
            <Typography>Shipping Date:</Typography>
            <Typography>{successRequest?.shippingDate}</Typography>
          </Box>
          <Box>
            <Typography>Provider:</Typography>
            <Typography>{successRequest?.shipmentProvider}</Typography>
          </Box>
        </Stack>
        <Divider sx={{ my: 1 }} />

        <Stack>
          <Button
            color="primary"
            component="a"
            href={successRequest?.labelUrl}
            download
            variant="outlined"
          >
            Download Label
          </Button>
          <Button
            color="primary"
            component="a"
            href={successRequest?.deliveryNoteUrl}
            download
            variant="outlined"
          >
            Download Deliverynote
          </Button>
        </Stack>
      </Box>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <ButtonGroup size="small">
          <Button
            color="error"
            onClick={() => dispatch(actions.SHIPMENT_MODAL.backStep())}
          >
            Back
          </Button>
          <Button
            color="success"
            onClick={() => {
              dispatch(actions.SHIPMENT_MODAL.openModal());
            }}
          >
            Close
          </Button>
        </ButtonGroup>
      </Box>
    </>
  );
}

export default ReseumeeShipment;
