import { Typography, Box } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions';
import BasicTable from 'src/components/Table';
import Loading from 'src/components/Loading';

const columns = [];

function WareHousePage() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(actions.WAREHOUSE.getAllWarehouse());
  }, []);

  const { isLoading, isError, data } = useSelector((state) => state.warehouse);

  if (isLoading) return <Loading color="success" />;

  if (data.length === 0) {
    return <Typography>Nada</Typography>;
  }

  return (
    <>
      <BasicTable columns={columns} rows={data} navigatePath={'/article/'} />
    </>
  );
}

export default WareHousePage;
