import env from "../../env";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

//Async Functions
export const getAllWarehouse = createAsyncThunk(
  "warehouse/getAllWarehouse",
  async (arg) => {
    const res = await axios
      .get(
        `${env.API_URL}/warehouses?pagination[limit]=-1`,
        {
          headers: {
            jwt: localStorage.getItem("microsoftJWT"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.data)
      .catch((err) => err.response);
    return res;
  }
);
