import React from 'react';
import { Divider, Button, Box, TextField, Typography } from '@mui/material';
import { checkArrayForText } from 'src/utils/rmasUtils';

function sortObjectByKeys(obj, sortedKeys) {
  const sortedObj = {};

  // Loop over each key in the sortedKeys array
  for (const key of sortedKeys) {
    if (obj.hasOwnProperty(key)) {
      sortedObj[key] = obj[key];
    }
  }

  // Loop over each key in the original object
  // to include keys that are not present in the sortedKeys array
  for (const key in obj) {
    if (!sortedObj.hasOwnProperty(key)) {
      sortedObj[key] = obj[key];
    }
  }

  return sortedObj;
}

const InternalRow = ({ keyObj, value }) => {
  const [isHovered, setIsHovered] = React.useState(false);
  return (
    value && (
      <Box
        flexGrow={5}
        flexShrink={1}
        flexBasis="auto"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          borderLeft: 1.5,
          borderColor: 'black',
          width: 300,
          p: 0.8,
          borderRadius: 1,
          transition: 'all 0.3s ease-in-out',
          ...(isHovered && {
            transform: 'scale(1.01)',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
          }),
        }}
      >
        <Typography variant="subtitle2">
          {keyObj[0].toUpperCase() + keyObj.slice(1)}
        </Typography>
        <Divider sx={{ mb: 0.5 }} />
        <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
          {value && value[0].toUpperCase() + value.substring(1)}
        </Typography>
      </Box>
    )
  );
};

function NewArticleBox({ title, obj, borderColor }) {
  return (
    <Box
      sx={{
        width: '100%',
        p: 2,
        borderLeft: 3,
        borderColor: borderColor,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      }}
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-start"
      gap={2}
    >
      <Box sx={{ width: '100%' }}>
        <Typography variant="subtitle2">{title}</Typography>
        <Divider />
      </Box>

      <Box display="flex" flexWrap="wrap" justifyContent="center" gap={2}>
        {Object.entries(obj).map((item, index) => {
          const [key, value] = item;
          return (
            <InternalRow
              key={`value_newArticleBox__${index}`}
              keyObj={key}
              value={value}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default NewArticleBox;
