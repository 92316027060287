import { Paper, Button, Typography, ButtonGroup, Box, TextField } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions';
import { normalizeValue } from 'src/utils/rmasUtils';

function CustomerAdress() {
  const dispatch = useDispatch();
  const { activeStep, shipment } = useSelector((state) => state.shipment_modal);
  const { newAdress } = useSelector((state) => state.rma.data.attributes);
  const {
    street,
    houseNo,
    zipCode,
    city,
    countryCode,
    customer,
    email,
    phone,
    salesperson,
  } = useSelector((state) => state.rma.data.attributes.debitor.data.attributes);

  const fields = [
    { name: 'name1', required: true },
    { name: 'name2', required: false },
    { name: 'name3', required: false },
    { name: 'streetName', required: true },
    { name: 'streetNumber', required: true },
    { name: 'zip', required: true },
    { name: 'city', required: true },
    { name: 'countryISOCode', required: true },
    { name: 'phone', required: true },
    { name: 'email', required: true },
    { name: 'contact_person', required: true },
  ];

  let meKnow = () => {
    if (shipment['name1'].length > 0) {
      return shipment;
    } else if (newAdress !== null) {
      //TODO RIGHT MAPPING
      return newAdress;
    } else {
      return {
        name1: customer,
        name2: '',
        name3: '',
        streetName: street,
        streetNumber: houseNo,
        zip: zipCode,
        city: city,
        countryISOCode: countryCode,
        phone: phone,
        email: email,
        contact_person: salesperson,
      };
    }
  };

  let now = meKnow();

  const [formData, setFormData] = React.useState(now);
  const [errors, setErrors] = React.useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Reset error for the specific field
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: '',
      });
    }
  };

  const handleValidation = () => {
    let fieldErrors = {};
    fields.forEach((field) => {
      if (field.required && (!formData[field.name] || !formData[field.name].trim())) {
        fieldErrors[field.name] = `${normalizeValue(field.name)} is required`;
      }
    });
    setErrors(fieldErrors);
    return Object.keys(fieldErrors).length === 0; // Return true if no errors
  };
  

  const handleNextClick = () => {
    if (handleValidation()) {
      dispatch(actions.SHIPMENT_MODAL.addShipmentData(formData));
      dispatch(actions.SHIPMENT_MODAL.nextStep());
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} component="form">
      {fields.map((item) => {
        const { name, required } = item;
        return (
          <TextField
            name={name}
            required={required}
            value={formData[name]}
            onChange={handleChange}
            label={normalizeValue(name)}
            variant="outlined"
            fullWidth
            error={!!errors[name]}
            helperText={errors[name]}
          />
        );
      })}
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        <ButtonGroup size="small">
          {activeStep !== 0 && (
            <Button
              color="error"
              onClick={() => dispatch(actions.SHIPMENT_MODAL.backStep())}
            >
              Back
            </Button>
          )}
          <Button color="success" onClick={handleNextClick}>
            Next
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default CustomerAdress;
