import React from 'react';
import { Paper, Typography, Divider, Button } from '@mui/material';

function AccountPage() {
  return (
    <Paper>
      <Typography>ACCOUNT PAGE</Typography>
      <Divider />
      <Button
        onClick={() => {
          localStorage.clear();
          window.location.reload();
        }}
      >
        LOGOUT
      </Button>
    </Paper>
  );
}

export default AccountPage;
