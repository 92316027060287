import { Divider, Stack, Typography } from '@mui/material';
import React from 'react';

function RowLineCustomer({ leftString, rightString, color }) {
  return (
    <Stack sx={{ display: 'flex', justifyContent: 'left', flexDirection: 'row' }}>
      <Typography variant="subtitle1">{leftString}</Typography>
      <Typography sx={{ marginLeft: 2, alignSelf: 'end' }}>{rightString}</Typography>
      <Divider variant="fullWidth" orientation="horizontal" flexItem />
    </Stack>
  );
}

export default RowLineCustomer;
