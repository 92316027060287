import { Chip } from '@mui/material';
import React from 'react';
import ComputerIcon from '@mui/icons-material/Computer';
import MemoryIcon from '@mui/icons-material/Memory';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';

function TypeShower({ type }) {
  let styled = { borderRadius: '6px' };

  switch (type) {
    case 'GPU':
      return (
        <Chip
          avatar={<MemoryIcon />}
          size="medium"
          label={'GPU'}
          color="primary"
          sx={styled}
          // variant="outlined"
        />
      );
    default:
      return (
        <Chip
          avatar={<DesktopMacIcon sx={{ bgcolor: 'transparent' }} />}
          sx={{ p: 2, ...styled }}
          size="medium"
          label={'BAREBONE'}
          color="primary"
          // variant="outlined"
        />
      );
  }
}

export default TypeShower;
