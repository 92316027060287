import { Tooltip, IconButton, Badge, Paper, Typography } from '@mui/material';
import React from 'react';
//
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';

function CardActionsIcons({ customerId }) {
  const navigate = useNavigate();

  const allTypeRmas = [
    {
      icon: <FiberNewOutlinedIcon />,
      name: 'Open',
      qty: 0,
      path: `/customer/${customerId}#new`,
      // action: () => console.log('hello'),
    },
    {
      icon: <PendingActionsOutlinedIcon />,
      name: 'Guarentee Check',
      qty: 0,
      path: `/customer/${customerId}#verify`,
      // action: () => console.log('hello'),
    },
    {
      icon: <CheckCircleOutlinedIcon />,
      name: 'Accepted',
      qty: 0,
      path: `/customer/${customerId}#accepted`,
      // action: () => console.log('hello'),
    },
    {
      icon: <LocalShippingOutlinedIcon />,
      name: 'Arrived',
      qty: 0,
      path: `/customer/${customerId}#shipment`,
      // action: () => console.log('hello'),
    },
    {
      icon: <BugReportOutlinedIcon />,
      name: 'Testing',
      qty: 0,
      path: `/customer/${customerId}#error`,
      // action: () => console.log('hello'),
    },
    {
      icon: <VerifiedUserOutlinedIcon />,
      name: 'Check Done',
      qty: 0,
      path: `/customer/${customerId}#done`,
      // action: () => console.log('hello'),
    },
    {
      icon: <LockOutlinedIcon />,
      name: 'Closed',
      qty: 0,
      path: `/customer/${customerId}#close`,
      // action: () => console.log('hello'),
    },
  ];

  return allTypeRmas.map((item, index) => {
    const { icon, name, qty, action, path } = item;

    return (
      <Paper
        sx={{
          transition: 'all 0.3s ease-in-out',
          ':hover': {
            transform: 'scale(1.1)',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)',
          },
        }}
        elevation={6}
      >
        <Tooltip title={name}>
          <Badge
          
            sx={{
              '& .MuiBadge-badge': {
                fontFamily: 'Lucida Console, Monaco, monospace',
                fontSize: '0.65rem',
              },
            }}
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={qty}
            color="secondary"
          >
            <IconButton
              onClick={() => {
                if (action) {
                  action();
                  navigate(path);
                }
                navigate(path);
              }}
              aria-label={name}
            >
              {icon}
            </IconButton>
          </Badge>
        </Tooltip>
      </Paper>
    );
  });
}

export default CardActionsIcons;
