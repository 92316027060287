import { createSlice } from '@reduxjs/toolkit';
import { postShipment } from './asyncShipmentModal';

const initialState = {
  open: false,
  activeStep: 0,
  articles: [],
  shipment: {
    name1: '',
    name2: '',
    name3: '',
    streetName: '',
    streetNumber: '',
    zip: '',
    city: '',
    countryISOCode: '',
    phone: '',
    email: '',
    contact_person: '',
  },
  shipment_details: {
    product: '',
    accountNumber: '',
    customerReference: '',
    weight: '5',
    length_cm: '60',
    width_cm: '30',
    height_cm: '15',
    recipientEmailAddress: '',
  },
  deliverynote: {
    rmaNumber: 1234222,
    shipping: {
      name: 'Max Mustermann',
      address: '123 Test',
      city: '10101 Binary',
      state: 'Germany',
      country: 'FR',
    },
  },
  successRequest: {},
  isLoading: true,
  isError: false,
};

const removeBySerial = (arr, serial) => {
  return arr.filter((item) => item.serial !== serial);
};

export const SHIPMENT_MODAL = createSlice({
  name: 'shipment_modal',
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      state.open = !state.open;
    },
    nextStep: (state, { payload }) => {
      if (payload === 0) {
        state.activeStep = payload;
      } else {
        state.activeStep = state.activeStep + 1;
      }
    },
    backStep: (state) => {
      if (state.activeStep !== 0) {
        state.activeStep = state.activeStep - 1;
      }
    },
    addItems: (state, { payload }) => {
      state.articles = [...state.articles, payload];
    },
    resetItems: (state, { payload }) => {
      state.articles = [];
    },
    removeItem: (state, { payload }) => {
      state.articles = removeBySerial(state.articles, payload);
    },
    addShipmentData: (state, { payload }) => {
      state.shipment = { ...state.shipment, ...payload };
    },
    resetShipment: (state, { payload }) => {
      state.shipment = {
        name1: '',
        name2: '',
        name3: '',
        streetName: '',
        streetNumber: '',
        zip: '',
        city: '',
        countryISOCode: '',
        phone: '',
        email: '',
        contact_person: '',
      };
      state.shipment_details = {
        product: '',
        accountNumber: '',
        customerReference: '',
        weight: '5',
        length_cm: '60',
        width_cm: '30',
        height_cm: '15',
        recipientEmailAddress: '',
      };
    },
    addShipmentDetails: (state, { payload }) => {
      state.shipment_details = { ...state.shipment_details, ...payload };
    },
    addDeliverynoteDetails: (state, { payload }) => {
      state.deliverynote = { ...state.deliverynote, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postShipment.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(postShipment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (payload?.status === 200) {
          state.successRequest = payload.data;
        }
      })
      .addCase(postShipment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  openModal,
  nextStep,
  backStep,
  addItems,
  resetItems,
  removeItem,
  addShipmentData,
  addShipmentDetails,
  addDeliverynoteDetails,
  resetShipment
} = SHIPMENT_MODAL.actions;

export default SHIPMENT_MODAL.reducer;
