// @mui
import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Link,
  Container,
  Typography,
  Divider,
  Stack,
  Button,
  TextField,
} from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
// import Logo from '../components/logo';
// import Iconify from '../components/iconify';
// // sections
// import { LoginForm } from '../sections/auth/login';
import msvg from '../assets/m_logo.svg';
import zsvg from '../assets/z_logo.svg';
import env from '../env';
import { useDispatch } from 'react-redux';
import actions from 'src/store/actions';
import { storeLogin } from 'src/utils/loginUtils';
import { useNavigate } from 'react-router-dom';
// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = React.useState({
    identifier: '',
    password: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    if (formData.identifier.length > 5) {
      e.preventDefault();
      dispatch(actions.ACCOUNT.localLogin(formData))
        .unwrap()
        .then((res) => {
          const { provider, data } = res;
          const user = data.user;
          const { username, blocked, customerCode, customerID, email } = user;
          console.log(res);
          if (res.status === 200) {
            storeLogin({
              provider,
              username,
              blocked,
              customerCode,
              customerID,
              email,
              isUser: true,
              isAdmin: false,
              isModerator: false,
              microsoftJWT: `L${data.jwt}`,
            });
            setTimeout(() => {
              navigate('/dashboard');
            }, 200);
          }
        });
    }
  };

  return (
    <>
      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back to
            </Typography>
            <img src={zsvg} alt="login" style={{ width: '80%', alignSelf: 'center' }} />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to Zotac Portal
            </Typography>

            <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
              <Button
                startIcon={
                  <img src={msvg} style={{ width: 25, height: 25 }} alt="msoft" />
                }
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
                onClick={() =>
                  (window.location.href = `${env.REACT_APP_SSO_BACKEND}/auth/sso/login`)
                }
              >
                LOGIN
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider>
            <form onSubmit={handleSubmit}>
              <Stack gap={2}>
                <TextField
                  required
                  name="identifier"
                  value={formData.identifier}
                  onChange={handleChange}
                  label="Email"
                  variant="outlined"
                />
                <TextField
                  required
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  type="password"
                  label="Password"
                  variant="outlined"
                />
                <Button
                  onClick={handleSubmit}
                  fullWidth
                  size="large"
                  color="inherit"
                  variant="contained"
                  type="submit"
                >
                  SEND
                </Button>
              </Stack>
            </form>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
