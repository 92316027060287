import axios from 'axios';
import env from '../env';

export function storeLogin(params, cbClear) {
  Object.entries(params).map((item, index) => {
    const key = item[0];
    const value = item[1];
    if (typeof value !== 'object') {
      localStorage.setItem(key, value);
      console.log(key, value);
    }
  });
}

export async function getProfileImage() {
  return await axios
    .get(`${env.REACT_APP_SSO_BACKEND}/auth/me`, {
      headers: {
        jwt: localStorage.getItem('microsoftJWT'),
      },
    })
    .then((response) => {
      localStorage.setItem('profileImage', response.data['profileImage']);
    });
}

export async function recallLoginSSO(dispatchAction) {
  if (localStorage.getItem('provider') === 'sso') {
    sessionStorage.setItem('redirect', window.location.pathname);
    window.location.href = `${env.REACT_APP_SSO_BACKEND}/auth/sso/login`;
  }
}
