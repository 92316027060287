import { DeleteForever, EditAttributes } from '@mui/icons-material';
import { Stack, Paper, Divider } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

function checkType(variable) {
  switch (typeof variable) {
    case 'string':
      return variable;
    case 'object': {
      const confirm = new Date(variable);
      return confirm.toISOString().split('T')[0];
    }
    default:
      return '';
  }
}

function DevicesAddRow({ article, actionDelete, actionEdit, articleXLSXArrIndex }) {
  const { isEditMode } = useSelector((state) => state.customer);

  return (
    <Paper sx={{ p: 1, my: 2 }} elevation={12}>
      <Stack spacing={1}>
        <Stack
          divider={<Divider orientation="vertical" variant="middle" flexItem />}
          useFlexGap
          spacing={2}
          flexDirection={'row'}
        >
          <div>
            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Part Number</p>
            {isEditMode ? (
              <input
                onChange={(e) => {
                  console.log(e.currentTarget.value);
                }}
                defaultValue={article[1]}
              />
            ) : (
              <p style={{ fontSize: 12 }}>{article[1]}</p>
            )}
          </div>
          <div>
            <p style={{ fontSize: 12, fontWeight: 'bold' }}>SERIAL</p>
            {isEditMode ? (
              <input
                onChange={(e) => {
                  console.log(
                    articleXLSXArrIndex,
                    article[1],
                    article[3],
                    e.currentTarget.value
                  );
                }}
                defaultValue={article[3]}
              />
            ) : (
              <p style={{ fontSize: 12 }}>{article[3]}</p>
            )}
          </div>
          <div>
            <p style={{ fontSize: 12, fontWeight: 'bold' }}>SKU Number</p>
            {isEditMode ? (
              <input value={article[2]} />
            ) : (
              <p style={{ fontSize: 12 }}>{article[2]}</p>
            )}
          </div>
          <div>
            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Invoice No</p>
            {isEditMode ? (
              <input value={article[4]} />
            ) : (
              <p style={{ fontSize: 12 }}>{article[4]}</p>
            )}
          </div>
          <div>
            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Invoice Datum</p>
            <p style={{ fontSize: 12 }}>{checkType(article[5])}</p>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Actions</p>
            <Stack useFlexGap spacing={1} flexDirection={'row'}>
              <DeleteForever
                sx={{
                  cursor: 'pointer',
                  ':hover': { backgroundColor: 'lightgray', borderRadius: '2px' },
                }}
                onClick={actionDelete}
                fontSize="small"
              />
              {/* <EditAttributes
                sx={{
                  cursor: 'pointer',
                  ':hover': { backgroundColor: 'lightgray', borderRadius: '2px' },
                }}
                onClick={actionEdit}
                fontSize="small"
              /> */}
            </Stack>
          </div>
        </Stack>
        {article[6] !== undefined && (
          <div>
            <p style={{ fontSize: 12, fontWeight: 'bold' }}>Problem</p>
            <p style={{ fontSize: 12 }}>{article[6]}</p>
          </div>
        )}
        {article[7] !== undefined && (
          <div>
            {article[6] === undefined ? (
              <p style={{ fontSize: 12, fontWeight: 'bold' }}>Problem</p>
            ) : null}
            <p style={{ fontSize: 12 }}>{article[7]}</p>
          </div>
        )}
      </Stack>
    </Paper>
  );
}

export default DevicesAddRow;
