import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Slide,
  Stack,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Loading from './Loading';

import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import actions from 'src/store/actions';
import RMARow from './RMARow';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomAccordion = styled(Accordion)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: box-shadow 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  }
`;

const CustomAccordionSummary = styled(AccordionSummary)`
  background-color: #c4cdd5;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export default function ZAccordion({ id, title, icon, openRouted }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const idSlice = id.substring(1);
  const customerID = useSelector((state) => state.customer.data.id);
  const arrToUse = useSelector((state) => state.filteredRma[idSlice]);
  const isLoading = useSelector((state) => state.filteredRma.isLoading[idSlice]);

  const [searchValueAccordion, setSearchValueAccordion] = React.useState('');

  const getData = () => {
    dispatch(actions.RMA.getFilteredRma({ idSlice, searchValueAccordion }));
  };

  React.useEffect(() => {
    if (openRouted === id) {
      setOpen(openRouted === id);
      getData();
    }
  }, [openRouted, id]);

  return (
    <div id={id}>
      <CustomAccordion expanded={open}>
        <CustomAccordionSummary
          onClick={() => {
            if (!open) {
              getData();
            }
            setOpen(!open);
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack
            width={'100%'}
            flexDirection="row"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {icon}
              <Typography variant="subtitle">{title}</Typography>
            </Box>
            {open && (
              <TextField
                onClick={(e) => {
                  e.stopPropagation();
                }}
                value={searchValueAccordion}
                onChange={(e) => {
                  setSearchValueAccordion(e.currentTarget.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <IconButton
                        onClick={() => {
                          setSearchValueAccordion('');
                          getData();
                        }}
                      >
                        <DeleteIcon size="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          console.log('GO');
                          getData();
                        }}
                        edge="end"
                      >
                        <SearchIcon size="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size="small"
                label="Search"
              />
            )}
          </Stack>
        </CustomAccordionSummary>
        <AccordionDetails>
          <Slide direction="down" in={open} mountOnEnter unmountOnExit>
            <Stack
              sx={{ maxHeight: 400, overflowX: 'auto' }}
              flexWrap="wrap"
              justifyContent="center"
              gap={2}
            >
              {!isLoading ? (
                arrToUse.length > 0 ? (
                  arrToUse.map((item) => {
                    const { attributes, id } = item;
                    return (
                      <RMARow
                        key={`RMARow_${id}`}
                        attributes={attributes}
                        id={id}
                        customerID={customerID}
                      />
                    );
                  })
                ) : (
                  <Typography variant="caption">No Results!</Typography>
                )
              ) : (
                <Loading color="success" />
              )}
            </Stack>
          </Slide>
        </AccordionDetails>
      </CustomAccordion>
    </div>
  );
}
