import { configureStore } from '@reduxjs/toolkit';
import ACCOUNT_SLICE from './account/account';
import CUSTOMERS_SLICE from './customers/customers';
import CUSTOMER_SLICE from './customer/customer';
import RMA_SLICE from './rma/rma';
import ARTICLE_SLICE from './article/article';
import WAREHOUSE_SLICE from './warehouse/warehouse';
import RMAS_SLICE from './rmas/rmas';
import FILTERED_RMAS_SLICE from './filteredRma/sliceFilteredRma';
import SHIPMENT_MODAL from './shipmentModal/shipmentModalSlice';
import DASHBOARD_SLICE from './dashboard/sliceDashboard';
import REPORT_SLICE from './report/sliceReport';

export const store = configureStore({
  reducer: {
    account: ACCOUNT_SLICE,
    customers: CUSTOMERS_SLICE,
    customer: CUSTOMER_SLICE,
    rmas: RMAS_SLICE,
    article: ARTICLE_SLICE,
    rma: RMA_SLICE,
    warehouse: WAREHOUSE_SLICE,
    filteredRma: FILTERED_RMAS_SLICE,
    shipment_modal: SHIPMENT_MODAL,
    dashboard: DASHBOARD_SLICE,
    report: REPORT_SLICE,
  },
});
