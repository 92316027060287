import React, { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Input,
  Slide,
  Button,
  IconButton,
  InputAdornment,
  ClickAwayListener,
  Tooltip,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// component
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux';
import actions from 'src/store/actions';
import DeleteIcon from '@mui/icons-material/Delete';
// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [criteria, setCriteria] = React.useState('');
  const dispatch = useDispatch();
  const path = window.location.pathname;

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = () => {
    switch (String(window.location.pathname)) {
      case '/customers':
        dispatch(actions.CUSTOMERS.getAllCustomers({ criteria, searchValue }));
        break;
      case '/rmas': {
        if (criteria == 'serial') {
          dispatch(actions.RMA.getFilteredArticle(searchValue));
        } else {
          dispatch(actions.RMA.getAllRmas({ criteria, searchValue }));
        }
        break;
      }
      case '/warehouse': {
        dispatch(actions.RMA.getAllRmas(searchValue));
        break;
      }
      default:
        console.log('hello');
        break;
    }
  };

  const clearSearch = () => {
    switch (String(window.location.pathname)) {
      case '/customers': {
        setSearchValue('');
        dispatch(actions.CUSTOMERS.getAllCustomers());
        break;
      }
      case '/rmas': {
        setSearchValue('');
        setCriteria('');
        dispatch(actions.RMA.getAllRmas());
        break;
      }
      default:
        console.log('hello');
        break;
    }
  };

  if (path === '/customers' || path === '/rmas') {
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          {!open && (
            <>
              <IconButton onClick={handleOpen}>
                <SearchIcon />
              </IconButton>
              {path === '/customers' && (
                <FormControl size="small" sx={{ width: '180px', ml: 2 }}>
                  <InputLabel id="demo-simple-select-label">Search Criteria</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={criteria}
                    defaultValue="customer"
                    label="Search Criteria"
                    onChange={(e) => {
                      setCriteria(e.target.value);
                      handleOpen();
                    }}
                  >
                    <MenuItem value={'customerCode'}>Customer Code</MenuItem>
                    <MenuItem value={'customer'}>Customer Name</MenuItem>
                    <MenuItem value={'salesperson'}>Salesperson</MenuItem>
                  </Select>
                </FormControl>
              )}
            </>
          )}
          {path === '/rmas' && (
            <FormControl size="small" sx={{ width: '180px', ml: 2 }}>
              <InputLabel id="rma-simple-select-label">Search Options</InputLabel>
              <Select
                labelId="rma-simple-select-label"
                id="rma-simple-select"
                value={criteria}
                defaultValue="number"
                label="Search Options"
                onChange={(e) => {
                  setCriteria(e.target.value);
                  handleOpen();
                }}
              >
                <MenuItem value={'serial'}>Serial Article</MenuItem>
                <MenuItem value={'number'}>Rma Number</MenuItem>
                <MenuItem value={'externalReference'}>External Reference</MenuItem>
              </Select>
            </FormControl>
          )}

          <Slide direction="down" in={open} mountOnEnter unmountOnExit>
            <StyledSearchbar>
              <Input
                autoFocus
                fullWidth
                disableUnderline
                value={searchValue}
                onKeyDown={(e) =>
                  e.key === 'Enter'
                    ? handleSearch()
                    : e.key === 'Escape'
                    ? clearSearch()
                    : null
                }
                onChange={(e) => setSearchValue(e.currentTarget.value)}
                placeholder="🔍 Search..."
                startAdornment={
                  <Tooltip sx={{ cursor: 'pointer' }} title="[ESC] Clear Search">
                    <InputAdornment position="start">
                      <DeleteIcon
                        onClick={clearSearch}
                        sx={{
                          color: 'text.disabled',
                          width: 30,
                          height: 30,
                        }}
                      />
                    </InputAdornment>
                  </Tooltip>
                }
                sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              />
              <Tooltip title="[ENTER] Start Search">
                <Button variant="contained" onClick={handleSearch}>
                  Search...
                </Button>
              </Tooltip>
            </StyledSearchbar>
          </Slide>
        </div>
      </ClickAwayListener>
    );
  }
  return null;
}
