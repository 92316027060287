import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import actions from 'src/store/actions';
import { Avatar, Paper, Stack, Box, Divider } from '@mui/material';
import RowLineCustomer from './RowLineCustomer';
import RMAContainer from 'src/components/RMATable/RMAContainer';
import ReadXLSX from 'src/components/AddXlsx/ReadXLSX';
import { Typography } from '@mui/material';
import ZAccordion from 'src/components/ZAccordion';
//
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Loading from 'src/components/Loading';

function CustomerPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const { id } = params;

  React.useEffect(() => {
    dispatch(actions.CUSTOMERS.getCustomer(id));
  }, [dispatch, id]);

  const [openRouted, setOpenRouted] = React.useState('');

  const scrollToElement = (divID) => {
    const element = document.getElementById('#new');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  React.useEffect(() => {
    console.log(location.hash);
    if (location.hash) {
      setTimeout(() => {
        scrollToElement(location.hash);
        setOpenRouted(location.hash);
      }, 500);
    }
  }, [location]);

  const { isLoading, isError, data } = useSelector((state) => state.customer);

  // TODO
  const columns = [];

  if (isLoading) return <Loading color="success" />;
  if (isError) return <Typography>isError</Typography>;

  const { attributes } = data;

  const {
    customerID,
    createdAt,
    updatedAt,
    salesperson,
    customerCode,
    customer,
    customerCountry,
    rmaTerms,
    girArragement,
    updatedRMACentre,
    remarks,
    email,
    zipCode,
    city,
    street,
    houseNo,
  } = attributes;

  const accordionRows = [
    {
      title: 'NEW',
      id: '#new',
      icon: <FiberNewOutlinedIcon />,
      actionOnMount: () => console.log('Hello'),
    },
    {
      title: 'Guarentee Check',
      id: '#verify',
      icon: <PendingActionsOutlinedIcon />,
      actionOnMount: () => console.log('Hello'),
    },
    {
      title: 'Accepted',
      id: '#accepted',
      icon: <CheckCircleOutlinedIcon />,
      actionOnMount: () => console.log('Hello'),
    },
    {
      title: 'Arrived',
      id: '#shipment',
      icon: <LocalShippingOutlinedIcon />,
      actionOnMount: () => console.log('Hello'),
    },
    {
      title: 'Testing',
      id: '#error',
      icon: <BugReportOutlinedIcon />,
      actionOnMount: () => console.log('Hello'),
    },
    {
      title: 'Check Done',
      id: '#done',
      icon: <VerifiedUserOutlinedIcon />,
      actionOnMount: () => console.log('Hello'),
    },
    {
      title: 'Closed',
      id: '#close',
      icon: <LockOutlinedIcon />,
      actionOnMount: () => console.log('Hello'),
    },
  ];

  return (
    <>
      <Stack flexDirection={'column'} spacing={2}>
        <Stack
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(10px)',
            borderRadius: '15px',
            padding: '20px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box>
            <Typography variant="title">Customer Info</Typography>
            <Divider sx={{ mb: 1 }} />
          </Box>
          <Box sx={{ p: 2 }}>
            <Stack flexDirection="row" sx={{ alignItems: 'baseline' }}>
              <Avatar>{customer.slice(0, 2)}</Avatar>
              <p style={{ marginLeft: 8 }}>{customer}</p>
            </Stack>
            <Stack
              flexDirection="row"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
            >
              <RowLineCustomer leftString={'Customer ID'} rightString={customerID} />
              <Divider orientation="vertical" sx={{ mx: 2 }} />
              <RowLineCustomer leftString={'Salesperson'} rightString={salesperson} />
              <Divider orientation="vertical" sx={{ mx: 2 }} />
              <RowLineCustomer leftString={'Customer Code'} rightString={customerCode} />
              <Divider orientation="vertical" sx={{ mx: 2 }} />
              <RowLineCustomer leftString={'Email'} rightString={email} />
              <Divider orientation="vertical" sx={{ mx: 2 }} />
              <RowLineCustomer leftString={'Country'} rightString={customerCountry} />
              <Divider orientation="vertical" sx={{ mx: 2 }} />
              <RowLineCustomer
                leftString={'Street'}
                rightString={`${street}, ${houseNo}`}
              />
              <Divider orientation="vertical" sx={{ mx: 2 }} />
              <RowLineCustomer leftString={'City'} rightString={`${zipCode} ${city}`} />
              <Divider orientation="vertical" sx={{ mx: 2 }} />
            </Stack>
          </Box>
        </Stack>
        {email !== null ? (
          <ReadXLSX />
        ) : (
          <Typography color={'red'} variant="h1">
            THIS DEBITOR HAVE A ERROR!! CALL ADMINS
          </Typography>
        )}
      </Stack>

      <Box sx={{ mt: 4 }} />
      <Stack
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          backdropFilter: 'blur(10px)',
          borderRadius: '15px',
          padding: '20px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
        spacing={2}
      >
        <Box>
          <Typography variant="title">RMA List</Typography>
          <Divider sx={{ mb: 1 }} />
        </Box>
        {accordionRows.map((item) => {
          return (
            <ZAccordion
              key={item.id + 'key'}
              openRouted={openRouted}
              icon={item.icon}
              id={item.id}
              title={item.title}
            />
          );
        })}
      </Stack>
    </>
  );
}

export default CustomerPage;
