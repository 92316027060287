import env from '../../env';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { realStatus } from 'src/utils/rmasUtils';
import { setPendingValue } from './sliceFilteredRma';

//Async Functions
export const getFilteredRma = createAsyncThunk(
  'filteredRmas/getFilteredRma',
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    let { idSlice, searchValueAccordion } = arg;
    const customerID = state.customer.data.id;
    // Manage Pending Status
    thunkAPI.dispatch(setPendingValue(idSlice));
    searchValueAccordion = searchValueAccordion !== undefined ? searchValueAccordion : '';
    const res = await axios
      .get(
        `${
          env.API_URL
        }/rmas?filters[number][$contains]=${searchValueAccordion}&filters[status][$contains]=${realStatus(
          idSlice
        )}&filters[debitor]=${customerID}&pagination[limit]=-1&sort[0]=createdAt:desc&populate=*`,
        {
          headers: {
            jwt: localStorage.getItem('microsoftJWT'),
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then((data) => {
        if (idSlice !== undefined) {
          return { filtered: idSlice, data: data.data.data };
        }
        return data.data;
      })
      .catch((err) => {
        console.log(err);
        return thunkAPI.rejectWithValue(err.response.data);
      });
    return res;
  }
);
